import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { locationSelectOptions } from '@/react/data/location';
import {
  DurationTypes,
  PeriodTypes,
  PriceTypes,
} from '@/react/data/catalog/interfaces';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { Modalities } from '@/react/data/catalog/interfaces';
import StepActions from '@/react/components/wizard/step-actions.component';
import { MoneyField, NumberField } from '@/react/components/form';
import ExitRefundRequestFlowDialog from '@/react/components/refund/refund-request/exit-refund-request-flow-dialog.component';

const defaultValues = {
  amount: '',
  campus: '',
  city: '',
  course: '',
  courseType: '',
  disableDuration: false,
  duration: null,
  durationType: '',
  institution: '',
  link: '',
  modality: '',
  period: '',
  requestReason: '',
  salesType: '',
  state: '',
  workload: null,
};

const mainValidationSchema = z.object({
  amount: z.coerce
    .number()
    .min(1, 'Valor obrigatório')
    .positive('Valor deve ser maior que zero'),
  course: z.string().min(1, 'Curso obrigatório'),
  courseType: z.string().min(1, 'Categoria obrigatória'),
  institution: z.string().min(1, 'Instituição de ensino obrigatória'),
  link: z.union([z.literal(''), z.string().trim().url()]),
  requestReason: z.string(),
  salesType: z.nativeEnum(PriceTypes, {
    errorMap: () => {
      return { message: 'Tipo de pagamento obrigatório' };
    },
  }),
  workload: z.union([
    z.null(),
    z.coerce.number().int().positive('Valor deve ser maior que zero'),
  ]),
});

const modalitySchema = z
  .object({
    city: z.string(),
    campus: z.string(),
    modality: z.string().min(1, 'Modalidade obrigatória'),
    period: z.union([z.nativeEnum(PeriodTypes), z.literal('')]),
    state: z.string(),
  })
  .partial()
  .superRefine(({ city, campus, period, state, modality }, ctx) => {
    if (
      [Modalities.HYBRID, Modalities.PRESENTIAL].includes(
        modality as Modalities
      )
    ) {
      if (!state) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Estado obrigatório',
          path: ['state'],
        });
      }
      if (!city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Cidade obrigatória',
          path: ['city'],
        });
      }
      if (!campus) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Polo obrigatório',
          path: ['campus'],
        });
      }
      if (!period) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Turno obrigatório',
          path: ['period'],
        });
      }
    }
  });

// zod has a limitation with dependent fields, so it's best to have them in separate schema
// more about this: https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005
const durationSchema = z
  .object({
    disableDuration: z.boolean(),
    duration: z.union([z.null(), z.coerce.number().int()]),
    durationType: z.nativeEnum(DurationTypes),
  })
  .partial()
  .superRefine(({ disableDuration, duration, durationType }, ctx) => {
    if (!disableDuration) {
      if (duration === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valor obrigatório',
          path: ['duration'],
        });
      }
      if (duration < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valor deve ser maior que zero',
          path: ['duration'],
        });
      }
      if (!durationType) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Tipo obrigatório',
          path: ['duration'],
        });
      }
    }
  });

// z.intersection does not work to merge schemas here because
// we're using .refine/.superRefine which outputs ZodEffects instead of ZodObject.
// This is poorly documented in the zod documentation.
// As a workaround, we're using .and to merge the schemas.
// More about this:
// https://github.com/colinhacks/zod/issues/2474
// https://github.com/colinhacks/zod/issues/1147
const formValidationSchema = mainValidationSchema
  .and(modalitySchema)
  .and(durationSchema);

const FieldSkeleton = () => {
  return (
    <FormControl fullWidth>
      <Stack spacing={1}>
        <Typography component="div" variant="body3">
          <Skeleton width="20%" />
        </Typography>
        <Skeleton height={53} variant="rounded" />
      </Stack>
      <FormHelperText />
    </FormControl>
  );
};

const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};

const ExternalRefundForm = () => {
  const { actions, values } = useWizard();
  const formValues = values[WizardStepIds.EXTERNAL_FORM];
  const [exitCallback, setExitCallback] = React.useState(() => () => {});
  const [showDialog, setShowDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const bootstrapData = useBootstrapData();
  const loadingBootstrapData = bootstrapData.isFetching;

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    resetField,
    trigger,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema),
  });

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    reset({ ...defaultValues, ...formValues });
  }, [formValues]);

  React.useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const { disableDuration, modality, salesType, state: stateValue } = watch();

  const renderAsyncSelectOptions = (options = []) =>
    options.length > 0 ? (
      options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>Carregando...</MenuItem>
    );

  const handleNext = async (data) => {
    const formData = { ...data };
    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const payload = {
      ...defaultValues,
      ...formData,
    };

    actions.updateValues({
      [WizardStepIds.EXTERNAL_FORM]: payload,
    });
    actions.navigate(WizardStepIds.SUMMARY);
  };

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const handleGoBack = () => {
    if (isDirty) {
      setExitCallback(
        () => () => actions.navigate(WizardStepIds.ITEM_SOURCE_FORM)
      );
      setShowDialog(true);
    } else {
      actions.navigate(WizardStepIds.ITEM_SOURCE_FORM);
    }
  };

  // If taxonomy data is not available we should not render the form
  if (!loadingBootstrapData && !bootstrapData?.taxonomy) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="institution"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="institution">
                        Instituição de Ensino
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="institution"
                        inputProps={{ 'data-testid': 'institution-input' }}
                        placeholder="Digite o nome da Instituição"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="course"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="course">Curso</FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        id="course"
                        helperText={handleErrormessage(error)}
                        inputProps={{ 'data-testid': 'course-input' }}
                        placeholder="Digite o nome do Curso"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="courseType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="courseType">Categoria</FormLabel>
                        <Select
                          {...field}
                          data-testid="courseType-select"
                          displayEmpty={true}
                          error={!!error}
                          inputProps={{ id: 'courseType' }}
                          renderValue={(value) => {
                            return value
                              ? bootstrapData.taxonomy.course_types.find(
                                  (option) => option.type === value
                                )?.label
                              : 'Selecione';
                          }}
                          variant="outlined"
                        >
                          {renderAsyncSelectOptions(
                            bootstrapData.taxonomy.course_types.map(
                              (option) => ({
                                value: option.type,
                                label: option.label,
                              })
                            )
                          )}
                        </Select>
                      </Stack>
                      <FormHelperText>
                        {handleErrormessage(error)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="modality"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="modality">Modalidade</FormLabel>
                      <Select
                        {...field}
                        data-testid="modality-select"
                        displayEmpty={true}
                        error={!!error}
                        inputProps={{ id: 'modality' }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger(['state', 'city', 'campus', 'period']);
                        }}
                        renderValue={(value) => {
                          return value
                            ? bootstrapData.taxonomy.modalities.find(
                                (option) => option.value === value
                              )?.name
                            : 'Selecione';
                        }}
                        variant="outlined"
                      >
                        {renderAsyncSelectOptions(
                          bootstrapData.taxonomy.modalities.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))
                        )}
                      </Select>
                    </Stack>
                    <FormHelperText>{handleErrormessage(error)}</FormHelperText>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          {[Modalities.HYBRID, Modalities.PRESENTIAL].includes(
            modality as Modalities
          ) && (
            <>
              <Grid item xs={12} sm={6}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="state">Estado</FormLabel>
                          <Autocomplete
                            {...field}
                            data-testid="state-autocomplete"
                            id="state"
                            options={locationSelectOptions.states}
                            getOptionKey={(option) => option.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={handleErrormessage(error)}
                                placeholder="Selecione"
                              />
                            )}
                            onChange={(_, data, reason) => {
                              if (reason === 'clear') {
                                field.onChange(defaultValues.state);
                              } else if (data) {
                                field.onChange(data.value);
                              }
                              resetField('city', {
                                defaultValue: defaultValues.city,
                                keepError: true,
                              });
                              trigger('city');
                            }}
                            value={
                              locationSelectOptions.states.find(
                                (option) => option.value === field.value
                              ) ?? null
                            }
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="city"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel htmlFor="city">Cidade</FormLabel>
                            <Autocomplete
                              {...field}
                              fullWidth
                              id="city"
                              data-testid="city-autocomplete"
                              noOptionsText="Selecione um estado"
                              onChange={(_, data, reason) => {
                                if (reason === 'clear') {
                                  field.onChange(defaultValues.city);
                                } else if (data) {
                                  field.onChange(data.value);
                                }
                              }}
                              options={
                                locationSelectOptions.citiesByState[
                                  stateValue
                                ] || []
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    error={!!error}
                                    helperText={handleErrormessage(error)}
                                    placeholder="Selecione"
                                    {...params}
                                  />
                                );
                              }}
                              value={
                                locationSelectOptions.citiesByState[
                                  stateValue
                                ]?.find(
                                  (option) => option.value === field.value
                                ) ?? null
                              }
                            />
                          </Stack>
                        </FormControl>
                      );
                    }}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="period"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="period">Turno</FormLabel>
                          <Select
                            {...field}
                            data-testid="period-select"
                            displayEmpty={true}
                            error={!!error}
                            inputProps={{ id: 'period' }}
                            renderValue={(value) => {
                              return value
                                ? bootstrapData.taxonomy.periods.find(
                                    (option) => option.value === value
                                  )?.name
                                : 'Selecione';
                            }}
                            variant="outlined"
                          >
                            {renderAsyncSelectOptions(
                              bootstrapData.taxonomy.periods.map((option) => ({
                                value: option.value,
                                label: option.name,
                              }))
                            )}
                          </Select>
                        </Stack>
                        <FormHelperText>
                          {handleErrormessage(error)}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="campus"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="campus">Polo</FormLabel>
                          <TextField
                            {...field}
                            error={!!error}
                            id="campus"
                            inputProps={{ 'data-testid': 'campus-input' }}
                            placeholder="Digite o nome do polo"
                            helperText={handleErrormessage(error)}
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="workload"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="workload">
                        Carga horária semanal
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="workload"
                        inputProps={{ 'data-testid': 'workload-input' }}
                        InputProps={{
                          inputComponent: NumberField as any,
                          endAdornment: (
                            <InputAdornment disableTypography position="end">
                              horas semanais
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Ex.: 360"
                        variant="outlined"
                      >
                        {renderAsyncSelectOptions(
                          bootstrapData.taxonomy.course_types.map((option) => ({
                            value: option.type,
                            label: option.label,
                          }))
                        )}
                      </TextField>
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="duration">Duração</FormLabel>
                          <TextField
                            {...field}
                            disabled={disableDuration}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            id="duration"
                            InputProps={{
                              inputComponent: NumberField as any,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Controller
                                    control={control}
                                    name="durationType"
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <Select
                                          {...field}
                                          disabled={disableDuration}
                                          error={!!error}
                                          fullWidth
                                          variant="outlined"
                                          displayEmpty={true}
                                          renderValue={(value) => {
                                            return value
                                              ? bootstrapData.taxonomy.durations.find(
                                                  (option) =>
                                                    option.value === value
                                                )?.name
                                              : 'Selecione';
                                          }}
                                          onChange={(e) => {
                                            field.onChange(e.target.value);
                                            trigger('duration');
                                          }}
                                          sx={{
                                            backgroundColor: 'neutral.300',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                          }}
                                        >
                                          {renderAsyncSelectOptions(
                                            bootstrapData.taxonomy.durations.map(
                                              (option) => ({
                                                value: option.value,
                                                label: option.name,
                                              })
                                            )
                                          )}
                                        </Select>
                                      </>
                                    )}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Ex.: 1, 3, 6, 10"
                            sx={{
                              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                pr: '0',
                              },
                            }}
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="disableDuration"
                    render={({ field }) => (
                      <Stack spacing={{ xs: 0, sm: 1.5 }}>
                        <FormLabel
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                          &nbsp;
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputProps={
                                {
                                  'data-testid': 'disableDuration-checkbox',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                              }
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                trigger(['duration', 'durationType']);
                              }}
                            />
                          }
                          label={
                            <Typography variant="body3">Sem duração</Typography>
                          }
                        />
                      </Stack>
                    )}
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="salesType"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Stack spacing={1}>
                      <FormLabel>
                        Qual é o formato de pagamento do item?
                      </FormLabel>
                      <RadioGroup {...field} aria-labelledby="salesType">
                        <FormControlLabel
                          control={
                            <Radio
                              size="small"
                              inputProps={
                                {
                                  'data-testid': 'salesType-radio1',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                              }
                            />
                          }
                          label={
                            <Typography variant="body3">
                              Compra (pagamento à vista ou parcelado)
                            </Typography>
                          }
                          value={PriceTypes.one_off}
                        />
                        <FormControlLabel
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="body3">Mensalidade</Typography>
                          }
                          value={PriceTypes.tuition}
                        />
                      </RadioGroup>
                    </Stack>
                    <FormHelperText>{handleErrormessage(error)}</FormHelperText>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="amount">
                          {salesType === PriceTypes.tuition
                            ? 'Valor da mensalidade (R$)'
                            : 'Custo total do item (R$)'}
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="amount"
                          inputProps={{
                            currency: 'BRL',
                            'data-testid': 'amount-input',
                          }}
                          InputProps={{
                            inputComponent: MoneyField as any,
                            startAdornment: (
                              <InputAdornment
                                disableTypography
                                position="start"
                              >
                                R$
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="link"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="link">
                        Link do item
                        <Box component="span" sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}>
                          opcional
                        </Box>
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="link"
                        placeholder="Ex.: www.link.com"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="requestReason"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="requestReason">
                        Descrição ou justificativa
                        <Box component="span" sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}>
                          opcional
                        </Box>
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="requestReason"
                        multiline
                        placeholder="Descreva o motivo da compra para avaliação da sua empresa."
                        rows={4}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <StepActions>
          <LoadingButton color="primary" onClick={handleGoBack} variant="text">
            Voltar
          </LoadingButton>
          <LoadingButton color="primary" type="submit" variant="contained">
            Próximo
          </LoadingButton>
          <ExitRefundRequestFlowDialog
            open={showDialog}
            onClose={handleCloseDialog}
            onConfirm={exitCallback}
          />
        </StepActions>
      </form>
    </>
  );
};

export default ExternalRefundForm;
