import { useQuery } from '@tanstack/react-query';
import {
  RequestListResponse,
  RequestFilteredRefundsList,
} from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useResourceApprovalsList(
  requestParams: RequestFilteredRefundsList
) {
  const result = useQuery<RequestListResponse>({
    queryKey: ['useResourceApprovalsList', requestParams],
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      return SubsidyClient.getResourceApprovalsList({ requestParams, signal });
    },
  });

  const requestResourceApprovalsList = result.data?.resource_approvals || [];
  const totalPages = result.data?.metadata.total_pages || 0;

  return {
    requestResourceApprovalsList,
    totalPages,
    isFetching: result.isFetching,
    error: !!result.error,
  };
}
