import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import conclusionJourney from '@/react/assets/images/conclusion-journey.svg';

export type ResourceApprovalCreatedDialogProps = {
  actionsVisibility?: {
    sendInvoice?: boolean;
    waitApproval?: boolean;
  };
  open: boolean;
  resourceApprovalUUID?: string;
};

const ResourceApprovalCreatedDialog: React.FC<
  ResourceApprovalCreatedDialogProps
> = ({ actionsVisibility = {}, open: externalOpen, resourceApprovalUUID }) => {
  const { sendInvoice = true, waitApproval = true } = actionsVisibility;
  const [open, setOpen] = React.useState(externalOpen);

  React.useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  const handleRedirectToReceipts = () => {
    window.location.assign(`/meus-reembolsos/solicitacao/${resourceApprovalUUID}/comprovante`);
    setOpen(false);
  };

  const handleRedirectToShow = () => {
    window.location.assign(`/meus-reembolsos/solicitacao/${resourceApprovalUUID}/descricao`);
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle />
      <DialogContent>
        <Stack spacing={3}>
          <Box component="img" src={conclusionJourney} sx={{ width: 128 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            O item foi enviado para aprovação!
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography component="p" variant="body3">
            Você pode conferir o andamento da sua solicitação na página de reembolsos. Se você já adquiriu o item, pode adiantar o processo enviando os comprovantes de compra e pagamento agora.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        {sendInvoice && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ 
              width: '100%',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              color="primary"
              onClick={handleRedirectToShow}
              sx={{ order: { xs: 1, md: 0 }}}
            >
              Fechar
            </Button>
            <Button
              color="primary"
              onClick={handleRedirectToReceipts}
              variant="contained"
              sx={{ order: { xs: 0, md: 1 }}}
            >
              Enviar Comprovantes
            </Button>
          </Stack>
        )}
        {waitApproval && (
          <Button color="primary" href="/meus-reembolsos/" variant="contained">
            Aguardar Aprovação
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResourceApprovalCreatedDialog;
