import * as React from 'react';
import NukaCarousel from 'nuka-carousel';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import type { StandardLonghandProperties } from 'csstype';
import type { Theme } from '@mui/material';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { hasProperty } from 'powership';
import type { ReactNode } from 'react';
import { useGtm } from '@/react/components/state/useGtm';
import { TagsGTMTrackClickBannerOfferEvent } from '@/libs/googleTagManager/interfaces';

export type CarouselProps = {
  height?: number;
  infinite?: boolean;
  numberOfSlidesOnViewport?: number;
  slides: CarouselSlide[];
};

export type CarouselResponsiveMedia = {
  objectFit?: StandardLonghandProperties['objectFit'];
  objectPosition?: StandardLonghandProperties['objectPosition'];
  backgroundColor?:
    | StandardLonghandProperties['backgroundColor']
    | ((theme: Theme) => string);
} & (
  | {
      // the slide item can receive an img src or a custom children
      children: ReactNode;
      src?: undefined;
    }
  | { children?: undefined; src: string }
);

export type CarouselSlide = CarouselResponsiveMedia & {
  title: string;
  md?: Partial<CarouselResponsiveMedia>;
  lg?: Partial<CarouselResponsiveMedia>;
  xl?: Partial<CarouselResponsiveMedia>;
  link: string | null;
  target?: string | null;
  tagsGTM: TagsGTMTrackClickBannerOfferEvent;
};

const defaultHeight = 300;
const defaultNumberOfSlidesOnViewport = 1;
const defaultInfiniteFlag = false;

interface ImageSlideProps {
  height: number;
  slide: CarouselSlide;
  onClick: (event: React.MouseEvent, slide: CarouselSlide) => void;
}

const ImageSlide = ({ height, slide, onClick }: ImageSlideProps) => {
  const { isMediumScreen, isSmallScreen, isLargeScreen, isXLargeScreen } =
    useScreenSizes();

  function pick(prop: keyof CarouselResponsiveMedia): any {
    if (isSmallScreen) return slide[prop];

    if (isMediumScreen) {
      if (hasProperty(slide.md, prop)) {
        return slide.md[prop];
      }
    }

    if (isLargeScreen) {
      if (hasProperty(slide.lg, prop)) {
        return slide.lg[prop];
      }
      if (hasProperty(slide.md, prop)) {
        return slide.md[prop];
      }
    }

    if (isXLargeScreen) {
      if (hasProperty(slide.xl, prop)) {
        return slide.xl[prop];
      }
      if (hasProperty(slide.lg, prop)) {
        return slide.lg[prop];
      }
      if (hasProperty(slide.md, prop)) {
        return slide.md[prop];
      }
    }

    return slide[prop];
  }

  const imgSRC = pick('src');
  const children = pick('children');

  return (
    <Button
      variant="text"
      href={slide.link}
      target={slide.target ? slide.target : undefined}
      onClick={(event) => {
        onClick(event, slide);
      }}
      fullWidth
      disableRipple
      style={{
        backgroundColor: pick('backgroundColor'),
        padding: 0,
        cursor: slide.link ? 'pointer' : 'default',
      }}
    >
      <Box
        component={imgSRC ? 'img' : undefined}
        src={imgSRC}
        children={children}
        alt={slide.title}
        title={slide.title}
        style={{
          height,
          width: '100%',
          backgroundColor: pick('backgroundColor'),
          objectFit: pick('objectFit'),
          objectPosition: pick('objectPosition'),
        }}
      />
    </Button>
  );
};

export const Carousel = ({
  height = defaultHeight,
  infinite = defaultInfiniteFlag,
  numberOfSlidesOnViewport = defaultNumberOfSlidesOnViewport,
  slides,
}: CarouselProps) => {
  const gtm = useGtm();
  const handleSlideGTMClick = (
    event: React.MouseEvent,
    slide: CarouselSlide
  ) => {
    event.preventDefault();
    const { tagsGTM, link, target } = slide;
    gtm.trackClickBannerOfferEvent({ tagsGTM, link });

    setTimeout(() => {
      if (target === '_blank') {
        window.open(link, target);
      } else {
        window.location.href = link;
      }
    }, 150);
  };

  const renderCenterLeftControls = ({ previousSlide, currentSlide }) => {
    const disabled = infinite ? false : currentSlide === 0;

    return (
      <Box ml={2}>
        <Fab
          color="primary"
          disabled={disabled}
          onClick={previousSlide}
          size="small"
        >
          <ChevronLeft />
        </Fab>
      </Box>
    );
  };

  const renderCenterRightControls = ({
    nextSlide,
    slideCount,
    currentSlide,
  }) => {
    const disabled = infinite ? false : currentSlide === slideCount - 1;

    return (
      <Box mr={2}>
        <Fab
          color="primary"
          disabled={disabled}
          onClick={nextSlide}
          size="small"
        >
          <ChevronRight />
        </Fab>
      </Box>
    );
  };

  const renderBottomCenterControls = ({
    slideCount,
    currentSlide,
    goToSlide,
  }) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={2}>
        {[...Array(slideCount)].map((e, index) => {
          const active = currentSlide === index;

          return (
            <IconButton
              color={active ? 'primary' : 'default'}
              key={index}
              onClick={() => goToSlide(index)}
              size="small"
            >
              <FiberManualRecordIcon fontSize="inherit" />
            </IconButton>
          );
        })}
      </Box>
    );
  };

  if (!slides?.length) {
    return null;
  }

  const isSingleSlide = slides.length === 1;

  return (
    <Card className={'Carousel'} style={{ boxShadow: 'none' }}>
      {isSingleSlide ? (
        <ImageSlide
          height={height}
          slide={slides[0]}
          onClick={handleSlideGTMClick}
        />
      ) : (
        <NukaCarousel
          slidesToShow={numberOfSlidesOnViewport}
          renderCenterLeftControls={renderCenterLeftControls}
          renderCenterRightControls={renderCenterRightControls}
          renderBottomCenterControls={renderBottomCenterControls}
          wrapAround={infinite}
        >
          {slides.map((slide, idx) => (
            <ImageSlide
              height={height}
              key={slide.src + idx}
              slide={slide}
              onClick={handleSlideGTMClick}
            />
          ))}
        </NukaCarousel>
      )}
    </Card>
  );
};
