import * as React from 'react';
import { State } from '@/react/components/wizard/state/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { WizardSteps } from '@/react/components/wizard/interfaces';
import { WizardProvider } from '@/react/components/wizard/state/context';
import Wizard from '@/react/components/wizard/wizard.component';
import { RefundDetails } from './refund-details/refund-details.component';

const createCourseSteps = (): WizardSteps => {
  return {
    [WizardStepIds.REFUND_DETAILS]: {
      component: () => <RefundDetails />,
    },
  };
};

const initialState = {
  currentStepId: WizardStepIds.REFUND_DETAILS,
} as State;

export const DescriptionSumaryStep = ({ steps }: { steps: WizardSteps }) => {
  return <Wizard steps={steps} />;
};

const DescriptionStepSummaryWithContext = () => {
  const courseSteps = createCourseSteps();

  return (
    <WizardProvider initialState={initialState}>
      <DescriptionSumaryStep steps={courseSteps} />
    </WizardProvider>
  );
};

export default DescriptionStepSummaryWithContext;
