import * as React from 'react';
import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';
import { constants } from '@/react/constants';

import { CursosSection } from './section.component';
import CourseTypeList from './course-type-list.component';
import CourseTypeListLoading from './course-type-list-loading.component';
import KnowledgeAreaList from './knowledge-area-list.component';
import KnowledgeAreaListLoading from './knowledge-area-list-loading.component';
import { Carousel, CarouselSlide } from '@/react/components/carousel';

import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';
import CONST from '@/constants';
import { User } from '@/models/user.model';
import { BootstrapData, CourseType } from './home.interfaces';
import { HomeRecommendations } from './home-recommendations';
import { GtmContext } from '../state/context';
import axios from 'axios';

// FIXME: This should consider production/staging/development envs
const baseUrl = 'https://assets.galena.com/edupass-api/public/home-banners';

const slides: CarouselSlide[] = [
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'feature_black_friday',
      creative_name: 'creative_name_banne_feature_black_friday',
      creative_slot: 'creative_slot_banne_feature_black_friday',
      promotion_id: 'promotion_id_banne_feature_black_friday',
      promotion_name: 'promotion_name_banne_feature_black_friday',
    },
    objectFit: 'cover',
    src: `${baseUrl}/blackfriday-2024/Banner-1-P.png`,
    link: '/cursos/novo_catalogo?page=1&feature_black_friday=true',
    md: {
      src: `${baseUrl}/blackfriday-2024/Banner-1-M.png`,
    },
    lg: {
      src: `${baseUrl}/blackfriday-2024/Banner-1-G.png`,
    },
    title: `Banner promocional dizendo: 'Esquenta Black Friday: Cursos com até 85% OFF'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'feature_black_friday_institutions_312_273_339_9',
      creative_name: 'creative_name_banne_feature_black_friday_institutions_312_273_339_9',
      creative_slot: 'creative_slot_banne_feature_black_friday_institutions_312_273_339_9',
      promotion_id: 'promotion_id_banne_feature_black_friday_institutions_312_273_339_9',
      promotion_name: 'promotion_name_banne_feature_black_friday_institutions_312_273_339_9',
    },
    objectFit: 'cover',
    src: `${baseUrl}/blackfriday-2024/Banner-2-P.png`,
    link: '/cursos/novo_catalogo?feature_black_friday=true&page=1&institutions=312,273,339,9',
    md: {
      src: `${baseUrl}/blackfriday-2024/Banner-2-M.png`,
    },
    lg: {
      src: `${baseUrl}/blackfriday-2024/Banner-2-G.png`,
    },
    title: `Banner promocional dizendo: 'Esquenta Black Friday: Estude idiomas com ainda mais desconto'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'feature_black_friday_institutions_4_99_156_234_271_276_277_278_279_280_many',
      creative_name: 'creative_name_banne_feature_black_friday_institutions_4_99_156_234_271_276_277_278_279_280_many',
      creative_slot: 'creative_slot_banne_feature_black_friday_institutions_4_99_156_234_271_276_277_278_279_280_many',
      promotion_id: 'promotion_id_banne_feature_black_friday_institutions_4_99_156_234_271_276_277_278_279_280_many',
      promotion_name: 'promotion_name_banne_feature_black_friday_institutions_4_99_156_234_271_276_277_278_279_280_many',
    },
    objectFit: 'cover',
    src: `${baseUrl}/blackfriday-2024/Banner-3-P.png`,
    link: '/cursos/novo_catalogo?feature_black_friday=true&page=1&institutions=297,296,286,295,294,293,292,291,290,289,287,288,322,4,321,314,315,316,317,319,320,318,341,344,346,345,343,342,278,328,280,329,324,325,271,327,326,277,279,156,276,234,99',
    target: '_blank',
    md: {
      src: `${baseUrl}/blackfriday-2024/Banner-3-M.png`,
    },
    lg: {
      src: `${baseUrl}/blackfriday-2024/Banner-3-G.png`,
    },
    title: `Banner promocional dizendo: 'Esquenta Black Friday: Use o cupom QUERO30 e estude em renomadas instituições de ensino'`,
  },
];

const CursosHome = (props: { user: User | null }) => {
  const { user } = props;

  const { isPending: isBootstrapDataPending, data: bootstrapData } =
    useQuery<BootstrapData>({
      queryKey: ['bootstrapData'],
      queryFn: () =>
        restClient.get('/users/bootstrap-data').then((res) => res.data.data),
    });

  const { info, plan, taxonomy } = bootstrapData || {};
  const courseTypes = taxonomy?.course_types || [];
  const knowledgeAreas = taxonomy?.knowledge_areas || [];

  const landingPageFeature = plan?.features.filter(
    (item) => item.name === 'unibrad_pos'
  );

  if (landingPageFeature && landingPageFeature.length > 0) {
    axios.get(plan?.promo_landing_page.url, {
      headers: {
        Authorization: `${plan?.promo_landing_page.token}`,
      },
    });

    slides.unshift({
      backgroundColor: '#CC092F',
      tagsGTM: {
        id: 'Novidade',
        creative_name: 'Summer Banner',
        creative_slot: 'featured_app_1',
        promotion_id: 'P_12345',
        promotion_name: 'Summer Sale',
      },
      objectFit: 'cover',
      src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-P.png`,
      link: `${plan?.promo_landing_page.url}`,
      target: '_blank',
      md: {
        src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-M.png`,
      },
      lg: {
        src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-G.png`,
      },
      title: `Unibrad - Pós-graduação por R$32,50`,
    });
  }

  const fullIncluded = plan?.features?.some(
    (el) => el.name === 'full_included'
  );

  const customCategory = info?.colaborador.empresa.custom_category;

  const universityCard = ((): CourseType | null => {
    const label = user?.colaborador?.empresa?.corporate_university_name;
    const logo = user?.colaborador?.empresa?.logo;

    return label && logo
      ? {
          label,
          link: '/corporate_university/trilhas',
          image: `${CONST.API_ENDPOINT}/contents/categorias/${logo}`,
          description: '',
          type: 'university',
        }
      : null;
  })();

  const courseTypesWithLink = (() => {
    const cards = [];

    if (landingPageFeature && landingPageFeature.length > 0) {
      cards.unshift({
        description: 'Pós-graduação por R$32,50',
        image: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner Card-M.png`,
        label: 'Unibrad',
        link: `${plan?.promo_landing_page.url}`,
        type: 'custom-category',
      });
    }

    if (universityCard) {
      cards.push(universityCard);
    }

    if (customCategory) {
      cards.push({
        description: 'Recomendados pela sua empresa',
        image: `${constants.API_ENDPOINT}/contents/categorias/${info.colaborador.empresa.logo}`,
        label: customCategory,
        link: '/cursos/custom-category',
        type: 'custom-category',
      });
    }

    if (fullIncluded) {
      cards.push({
        label: 'Inclusos no seu plano',
        link: 'cursos/novo_catalogo?feature_full=true',
        type: 'full',
      });
    }

    courseTypes.forEach((courseType) => {
      cards.push({
        ...courseType,
        link: `cursos/novo_catalogo?course_types=${courseType.type}`,
      });
    });

    return cards;
  })();

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <GtmContext>
          <CursosSection>
            <Carousel infinite={slides.length > 1} slides={slides} />
          </CursosSection>

          <HomeRecommendations />
        </GtmContext>

        <CursosSection title="Encontre o curso ideal para você">
          {isBootstrapDataPending && <CourseTypeListLoading />}
          {!isBootstrapDataPending && courseTypesWithLink.length > 0 && (
            <CourseTypeList data={courseTypesWithLink} />
          )}
        </CursosSection>

        <CursosSection title="Explore cursos por Temas">
          {isBootstrapDataPending && <KnowledgeAreaListLoading />}
          {!isBootstrapDataPending && knowledgeAreasWithLink.length > 0 && (
            <KnowledgeAreaList data={knowledgeAreasWithLink} />
          )}
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};

export default CursosHome;
