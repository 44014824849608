import {
  RefundRequestStatus,
  RefundResourceStatus,
  ResourceApproval,
} from '@/react/data/subsidy/interfaces';

export const statusMapResourceApproval: StatusMap = {
  [RefundResourceStatus.pending_approval]: 'waitingForRevisionStepTwo',
  [RefundResourceStatus.approved]: 'pendingDocumentationStepThree',
  [RefundResourceStatus.revision_required]:
    'needsResourceApprovalRevisionStepOne',
  [RefundResourceStatus.cancelled]: 'canceledResourceApprovalStepOne',
  [RefundResourceStatus.rejected]: 'declinedResourceApprovalStepTwo',
};

export const statusMapRefundRequest: StatusMap = {
  [RefundRequestStatus.pending_approval]:
    'waitingForDocumentationRevisionStepFour',
  [RefundRequestStatus.cancelled]: 'canceledRefundRequestStepThree',
  [RefundRequestStatus.rejected]: 'declinedRefundRequestStepFour',
  [RefundRequestStatus.revision_required]: 'needsRefundRequestRevisionStepFour',
  [RefundRequestStatus.approved]: 'pendingRefundPaymentStepFive',
  [RefundRequestStatus.paid]: 'paidRefundRequestStepFive',
};

export const useRefundStatus = (
  refundItem: ResourceApproval
): useRefundStatusOutput => {
  let mappedStatus: string;
  const { status, refund_request } = refundItem;
  const hasRefundRequest = !!refund_request;

  if (hasRefundRequest) {
    mappedStatus = statusMapRefundRequest[status] || 'unknown';
  } else {
    mappedStatus = statusMapResourceApproval[status] || 'unknown';
  }

  return {
    hasRefundRequest,
    mappedStatus,
    originalStatus: status,
  };
};

type useRefundStatusOutput = {
  hasRefundRequest: boolean;
  mappedStatus: string;
  originalStatus: string;
};

type StatusMap = {
  [key: string]: string;
};
