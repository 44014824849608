import * as React from 'react';

import { State } from './interfaces';

const initialState = {
  items: [],
  pagination: {
    total: 0,
    total_pages: 0,
    page: 1,
    page_size: 1,
  },
  loading: false,
  showcase: [],
};

export const Context = React.createContext(null);

export function CatalogContext(props) {
  const [catalogState, setCatalogState] = React.useState<State>(initialState);

  return (
    <Context.Provider value={[catalogState, setCatalogState]}>
      {props.children}
    </Context.Provider>
  );
}
