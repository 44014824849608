import React, { useState, useEffect } from 'react';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { Button, Grid, Box } from '@mui/material';
import Plan from '@/react/data/idp/plans/plan.model';
import { PlanStatuses } from '@/react/data/idp/plans';
import Typography from '@mui/material/Typography';
import { Activity } from '@/react/data/idp/activities';

enum ComponentStates {
  EMPTY = 'EMPTY',
  LOADED = 'LOADED',
  NULL = 'NULL',
}

interface ActivitiesListProps {
  plan: Plan;
  activities: Activity[];
}

const handleNewActivity = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}/atividades/nova`;
};

const ActivitiesActions = ({ plan }: { plan: Plan }) => {
  return (
    <>
      <Grid item xs={6} className="media-screen">
        <Button onClick={() => handleNewActivity(plan.id)} variant="text">
          Adicionar atividade
        </Button>
      </Grid>

      <Grid item xs={6} className="media-screen">
        <Button variant="text">Reordenar</Button>
      </Grid>
    </>
  );
};

export const ActivitiesList = ({ plan, activities }: ActivitiesListProps) => {
  const [componentState, setComponentState] = useState<ComponentStates>(
    ComponentStates.NULL
  );

  const renderState = {
    [ComponentStates.LOADED]: (
      <>
        {activities.map((activity) => (
          <ActivityCard planId={plan.id} activity={activity} />
        ))}
      </>
    ),
    [ComponentStates.EMPTY]: (
      <Typography>
        Adicione as atividades que você realizará para atingir seus objetivos
        com esse plano. Ex.: Fazer um curso de liderança, Procurar um mentor,
        Escrever um artigo, e etc...
      </Typography>
    ),
  };

  useEffect(() => {
    if (activities.length > 0) {
      setComponentState(ComponentStates.LOADED);
    }
    if (activities.length === 0) {
      setComponentState(
        plan.status !== PlanStatuses.CLOSED
          ? ComponentStates.EMPTY
          : ComponentStates.NULL
      );
    }
  }, [activities]);

  return componentState === ComponentStates.NULL ? null : (
    <>
      <Box paddingTop={2}>
        <Typography variant="h5">Atividades</Typography>
      </Box>
      <Box>
        {plan.status !== PlanStatuses.CLOSED && (
          <ActivitiesActions plan={plan} />
        )}
        {renderState[componentState]}
      </Box>
    </>
  );
};
