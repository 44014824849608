import * as React from 'react';
import { State } from '@/react/components/wizard/state/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/approval-step/interfaces';
import { WizardSteps } from '@/react/components/wizard/interfaces';
import { WizardProvider } from '@/react/components/wizard/state/context';
import Wizard from '@/react/components/wizard/wizard.component';
import { ApprovalSummary } from '@/react/components/refund/refund-request/approval-step/approval-summary/approval-summary.component';

const createCourseSteps = (): WizardSteps => {
  return {
    [WizardStepIds.APPROVAL_SUMMARY]: {
      component: (props) => (
          <ApprovalSummary />
      ),
    },
  };
};

const initialState = {
  currentStepId: WizardStepIds.APPROVAL_SUMMARY,
} as State;

export const ApprovalStep = ({ steps}: { steps: WizardSteps; }) => {
  return <Wizard steps={steps} />;
};

const ApprovalStepWithContext = () => {
  const courseSteps = createCourseSteps();

  return (
    <WizardProvider initialState={initialState}>
      <ApprovalStep steps={courseSteps} />
    </WizardProvider>
  );
};

export default ApprovalStepWithContext;
