import { Component, ViewEncapsulation } from '@angular/core';
import { PrintHeader } from '@/react/components/idp/print-header.component';

@Component({
  // Why are we changing encapsulation? Read this: https://angular.dev/guide/components/styling#style-scoping
  encapsulation: ViewEncapsulation.None, // Apply global styles (because of '@media print' query, we want to affect the whole page)
  selector: 'app-idp-container',
  styleUrls: ['./idp-container.component.scss'],
  templateUrl: './idp-container.component.html',
})
export class IdpContainerComponent {
  IdpPrintHeaderComponent = PrintHeader;
}
