import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import {
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { RefundListProvider } from '@/react/components/refund/refund-list/state';
import { RefundList } from '@/react/components/refund/refund-list';
import ShowPoliciesComponent from '@/react/components/refund/refund-policies/show-policies.component';
import { useScreenSizes } from '@/react/utils/useScreenSizes';

import HowRefundWorksDialog, { localStorageKey } from '@/react/components/refund/how-refund-works-dialog.component';

const ManageRefunds = () => {
  const { isSmallScreen } = useScreenSizes();
  const [openHowRefundWorksDialog, setOpenHowRefundWorksDialog] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

  const handleShowPolicies = () => {
    window.location.assign('/meus-reembolsos/minhas-politicas');
  };

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: isSmallScreen ? '24px' : '32px' }}
      >
        <Grid item xs={12} sm={8} md={9} marginBottom={1}>
          <Typography gutterBottom color="palette.200" variant="subtitle1">
            Reembolso
          </Typography>
          <Typography color="neutral.800" variant="body3" >
            Crie e gerencie solicitações de reembolso.{' '}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOpenHowRefundWorksDialog(true);
                setShowContinueButton(false);
              }}
              variant="body3"
            >
              Veja como funciona
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <ShowPoliciesComponent onclick={handleShowPolicies} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <RefundListProvider>
          <Grid item xs={12} md={3} mr={'auto'}>
          <Button
            color="primary"
            size="large"
            startIcon={<Add />}
            fullWidth
            sx={{ borderRadius: 0}}
            variant="contained"
            onClick={() => {
              const bypassOpen = !!localStorage.getItem(localStorageKey);
              if (bypassOpen) {
                window.location.assign('/meus-reembolsos/novo');
              } else {
                setOpenHowRefundWorksDialog(true);
                setShowContinueButton(true);
              }
            }}
          >
            Novo Reembolso
          </Button>
          </Grid>
          <RefundList />
        </RefundListProvider>
      </Grid>

      <HowRefundWorksDialog
        open={openHowRefundWorksDialog}
        onClose={() => {
          setOpenHowRefundWorksDialog(false);
          setShowContinueButton(false);
        }}
        showContinueButton={showContinueButton}
      />
    </Container>
  );
};

export default ManageRefunds;
