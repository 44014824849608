import React, { useState, useRef } from 'react';

import { Box, IconButton, Typography, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useClickOutside } from '@/react/hooks/useClickOutside';

const MenuItems = styled('a')(({ theme }) => ({
  color: theme.palette.neutral[800],
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

interface ActionMenuProps {
  actions: {
    label: string;
    onClick: () => void;
  }[];
}

export const ActionMenu = ({ actions }: ActionMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  useClickOutside({
    ref: menuRef,
    onClickedOutside: () => {
      setIsOpen(false);
    },
  });

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box ref={menuRef}>
      <IconButton aria-label="mais opções" size="small" onClick={toggleMenu}>
        <MoreVertIcon />
      </IconButton>
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            width: '194px',
            right: 0,
            background: '#FFFFFF',
            padding: '16px',
            textAlign: 'right',
          }}
        >
          {actions.map((action) => (
            <MenuItems
              onClick={() => {
                action.onClick();
                toggleMenu();
              }}
            >
              <Typography fontWeight="semibold">{action.label}</Typography>
            </MenuItems>
          ))}
        </Box>
      )}
    </Box>
  );
};
