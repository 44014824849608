import React from 'react';
import {
  DatePicker,
  LocalizationProvider,
  DatePickerProps,
} from '@mui/x-date-pickers';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

export interface CustomDatePickerProps {
  field: {
    onChange: (value: string | null) => void;
    [key: string]: any;
  };
  value: DateTime | null;
  slotProps?: DatePickerProps<any>['slotProps'];
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  field,
  value,
  slotProps = {},
}) => {
  const handleDateChange = (newValue: DateTime | null) => {
    const formattedValue =
      newValue && newValue.isValid ? newValue.toISODate() : null;
    field.onChange(formattedValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="pt-br">
      <DatePicker
        {...field}
        value={value}
        onChange={handleDateChange}
        slotProps={{
          ...slotProps,
          yearButton: {
            sx: {
              fontSize: 'inherit',
              lineHeight: 'unset',
            },
          },
        }}
        format="dd/MM/yyyy"
        localeText={{
          toolbarTitle: '',
        }}
      />
    </LocalizationProvider>
  );
};
