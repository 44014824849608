import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { PlanFeature } from '@/react/data/bootstrap/interfaces';

const desktopHeight = 300;
const mobileHeight = 128;

export interface CatalogBannerProps {
  altText?: string;
  backgroundCss?: string;
  bannerImageSmall: string;
  bannerImageMedium: string;
  bannerImageLarge: string;
}

export const getBannerFromPlanFeatures = (planFeatures: PlanFeature[]) => {
  const formatProps = (props: PlanFeature['catalog_search']) => ({
    altText: props.alt_text,
    backgroundCss: props.background_css,
    bannerImageSmall: props.banner_image_small,
    bannerImageMedium: props.banner_image_medium,
    bannerImageLarge: props.banner_image_large,
  });

  const banners = planFeatures
    .filter((feature) => feature.catalog_search)
    .map(({ catalog_search }) => formatProps(catalog_search));

  // TODO: what to do with multiple banners?
  return banners[0];
};

export const CatalogBanner = (props: CatalogBannerProps) => {
  const {
    altText = '',
    backgroundCss = '',
    bannerImageSmall,
    bannerImageMedium,
    bannerImageLarge,
  } = props;
  const { isMediumScreen, isLargeScreen, isXLargeScreen, desktopSize } =
    useScreenSizes();

  const imageSrc = (() => {
    if (isMediumScreen) return bannerImageMedium;
    if (isLargeScreen || isXLargeScreen) return bannerImageLarge;

    return bannerImageSmall;
  })();

  return (
    <Container
      fixed
      maxWidth="xl"
      {...(!desktopSize && { fixed: false, disableGutters: true })}
    >
      <Card
        data-testid="catalog-banner"
        {...(!desktopSize && { square: true })}
        sx={() => ({
          ...(backgroundCss && {
            background: backgroundCss,
          }),
          ...(desktopSize && {
            marginBottom: 3,
            marginTop: 3,
          }),
          ...(!desktopSize && {
            border: 0,
          }),
        })}
        variant="outlined"
      >
        <Box
          alt={altText}
          component="img"
          src={imageSrc}
          sx={{
            height: desktopSize ? desktopHeight : mobileHeight,
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </Card>
    </Container>
  );
};
