import * as React from 'react';
import { Grid, Box, LinearProgress } from '@mui/material';
import { nanoid } from 'nanoid';
import { RefundCard } from './refund-card.component';
import { EmptyList } from '@/react/components/refund/empty-list.component';
import FilterTabs from '@/react/components/filter-tabs/filter-tabs.component';
import Pagination from '@/react/components/pagination/pagination.component';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';
import { useResourceApprovalsList } from '@/react/data/subsidy/useResourceApprovalsList';
import { useRefundList } from './state';

const ITEMS_PER_PAGE = 4;

export const RefundList = () => {
  const {
    currentLabel,
    handlePageChange,
    handleTabChange,
    page,
    status,
    value,
  } = useRefundList();

  const labels = ['Todos', 'Abertos', 'Finalizados'];

  const requestParams = {
    status: status,
    page: page,
    per_page: ITEMS_PER_PAGE,
  };

  const { requestResourceApprovalsList, totalPages, isFetching, error } =
    useResourceApprovalsList(requestParams);

  const [totalPageCount, setTotalPageCount] = React.useState(0);

  React.useEffect(() => {
    if (totalPages) {
      setTotalPageCount(totalPages);
    }

    handlePageChange(page);
  }, [page, totalPages]);

  return (
    <FilterTabs value={value} onChange={handleTabChange} labels={labels}>
      {labels.map((label, index) => (
        <Box key={index + nanoid()}>
          {currentLabel.includes(label) && (
            <Grid item xs={12}>
              {isFetching && !error && <LinearProgress />}
              {!isFetching &&
                (requestResourceApprovalsList.length === 0 || error) && (
                  <EmptyList />
                )}
              {requestResourceApprovalsList.map(
                (resourceApproval: ResourceApproval, index: Number) => (
                  <Box key={index + nanoid()}>
                    <RefundCard resourceApproval={resourceApproval} />
                  </Box>
                )
              )}
              {requestResourceApprovalsList.length > 0 && (
                <Box display="flex" justifyContent="flex-end" my={2}>
                  <Pagination
                    currentPage={page}
                    totalPages={totalPageCount}
                    pageChange={handlePageChange}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Box>
      ))}
    </FilterTabs>
  );
};
