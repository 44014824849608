import * as React from 'react';
import Box from '@mui/material/Box';

import { WizardStep } from './interfaces';

const StepWrapper = ({ children }) => {
  return <Box>{children}</Box>;
};

const Step = ({ step }: { step: WizardStep }) => {
  const { component: Component, titleElement } = step;

  return (
    <StepWrapper>
      {titleElement}
      <Component />
    </StepWrapper>
  );
};

export default Step;
