import React from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import { nanoid } from 'nanoid';

const TabPanel = ({ children, value, index, ...other }) => {

  return (
    <Grid container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index + nanoid()}`}
      aria-labelledby={`simple-tab-${index + nanoid()}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} pt={2}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

function getTabAccessibilityProps(index) {
  return {
    id: `simple-tab-${index + nanoid()}`,
    "aria-controls": `simple-tabpanel-${index + nanoid()}`
  };
}

const FilterTabs = ({ value, onChange, labels, children }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: {xs: 'space-around', md: 'flex-end'},
          backgroundColor: 'transparent',
          boxShadow: 'none',
          my: 2,
          width: {xs: '100%', md: 'auto'},
        }}
      >
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="simple tabs example"
          sx={({palette}) => ({
            backgroundColor: palette.neutral[100],
            width: {xs: '100%', md: 'auto'},
          })}
        >
          {labels.map((label, index) => (
            <Tab key={index + nanoid()} label={label} {...getTabAccessibilityProps(index)}
            sx={({palette}) => ({
              width: {xs: `${100 / labels.length}%`, md: 'auto'},
              border: `1px solid ${palette.neutral[300]}`,
              background: palette.neutral[50],
              color: palette.primary[900],
                "&.Mui-selected": {
                  background: palette.primary[100],
                  border: `1px solid ${palette.primary[900]}`,
                  borderBottom: `1px solid ${palette.primary[900]}`,
                },
              })}
            />
          ))}
        </Tabs>
      </Box>
      {React.Children.map(children, (child, index) => (
        <TabPanel value={value} index={index}>
          {child}
        </TabPanel>
      ))}
    </>
  );
};

export default FilterTabs;
