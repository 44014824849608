import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { useRefundStatus } from '@/react/components/refund/utils/useRefundStatus';
import { formatDate } from '@/react/utils/date';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';

export const RefundUnderReview = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={4} justifyContent="center">
        <Box
          sx={({ palette, spacing }) => ({
            color: palette.neutral[800],
            marginBottom: spacing(2),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
            width: 'fit-content',
          })}
        >
          <Typography variant="body3">Aguarde: aprovação pendente</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const RefundDeclined = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={({ spacing }) => ({
          paddingBottom: spacing('0.5'),
          marginBottom: spacing(2),
        })}
      >
        <Chip
          label={
            resourceApproval.decided_at &&
            `Item reprovado em: ${formatDate(resourceApproval.decided_at)}`
          }
          variant="negativeRequestResponse"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body3">
          {resourceApproval.decision_comment
            ? resourceApproval.decision_comment
            : 'Item não corresponde ao pedido'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const RefundApproved = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        justifyContent="center"
        sx={({ palette, spacing }) => ({
          color: palette.neutral[800],
          backgroundColor: 'transparent',
          marginBottom: spacing(2),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}
      >
        <Typography variant="body3">
          {resourceApproval.decided_at &&
            `Pedido aprovado em: ${formatDate(resourceApproval.decided_at)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ApprovalSummary = () => {
  const { resourceApproval } = useRefundRequest();
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {mappedStatus === 'waitingForRevisionStepTwo' && <RefundUnderReview />}
      {(mappedStatus === 'declinedResourceApprovalStepTwo' ||
        mappedStatus === 'canceledResourceApprovalStepOne') && (
        <RefundDeclined resourceApproval={resourceApproval} />
      )}
      {[
        'pendingDocumentationStepThree',
        'waitingForDocumentationRevisionStepFour',
        'declinedRefundRequestStepFour',
        'needsRefundRequestRevisionStepFour',
        'pendingRefundPaymentStepFive',
        'paidRefundRequestStepFive',
      ].includes(mappedStatus) && (
        <RefundApproved resourceApproval={resourceApproval} />
      )}
    </>
  );
};
