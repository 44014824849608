import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CONST from '@/constants';

const Footer = () => {
  return (
    <Box bgcolor="neutral.50" sx={{ width: '100vw' }}>
      <Container fixed maxWidth="xl">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          flexWrap="wrap"
          justifyContent="space-between"
          py={{ xs: 2, sm: 3 }}
          spacing={3}
          textAlign={{ sm: 'center', md: 'left' }}
        >
          <Stack spacing={1}>
            <Typography variant="body3" sx={{ fontWeight: 'bold', mb: 1 }}>
              Precisa de ajuda?
            </Typography>
            <Stack spacing={1}>
              <Link
                href={CONST.GALENA_SERVICE_URL}
                target="_blank"
                rel="noopener"
              >
                <Typography variant="body3">Central de ajuda</Typography>
              </Link>
              <Link href={CONST.SUPPORT_URL} target="_blank" rel="noopener">
                <Typography variant="body3">Whatsapp</Typography>
              </Link>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
              Termos e condições
            </Typography>
            <Stack spacing={1}>
              <Link
                href={`${CONST.GALENA_WEBSITE_URL}/termos-de-uso/`}
                target="_blank"
                rel="noopener"
              >
                <Typography variant="body3">Termos de uso</Typography>
              </Link>
              <Link
                href={`${CONST.GALENA_WEBSITE_URL}/politica-de-privacidade/`}
                target="_blank"
                rel="noopener"
              >
                <Typography variant="body3">Política de privacidade</Typography>
              </Link>
            </Stack>
          </Stack>
          <Stack
            alignItems={{ xs: 'flex-start', sm: 'center', md: 'flex-start' }}
            spacing={1}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Typography fontSize="small">Versão {CONST.VERSION}</Typography>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Box>©</Box>
              <Stack
                spacing={{ xs: 1, md: '0', lg: 1 }}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                direction={{ xs: 'row', md: 'column', lg: 'row' }}
              >
                <Typography variant="body3">Galena 2024</Typography>
                <Typography
                  display={{ xs: 'block', md: 'none', lg: 'block' }}
                  variant="body3"
                >
                  |
                </Typography>
                <Typography variant="body3">
                  Todos os direitos reservados.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
