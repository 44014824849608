import * as React from 'react';
import { WizardProps } from './interfaces';

import Step from './step.component';
import { useWizard } from './state/useWizard';

const Wizard = ({ steps }: WizardProps) => {
  const { currentStepId } = useWizard();

  return (
    <>
      {Object.entries(steps).map(([key, step]) => {
        const current = currentStepId === key;

        if (current) {
          return <Step key={key} step={step} />;
        }
      })}
    </>
  );
};

export default Wizard;
