import { DocumentValidator } from './DocumentValidator';

export class CPFValidator extends DocumentValidator {
  private accumulateWeightedValues(
    sum: number,
    numbers: number[],
    weight: number
  ) {
    for (let i = 0; i < 9; i++) {
      sum += numbers[i] * weight--;
    }
    return { sum, weight };
  }

  protected getDocumentLength(): number {
    return 11;
  }

  protected getVerifierDigits(numbers: number[]): number[] {
    let sum = 0;
    let weight = 10;

    ({ sum, weight } = this.accumulateWeightedValues(sum, numbers, weight));

    let remainder = sum % 11;
    let digit1 = remainder < 2 ? 0 : 11 - remainder;

    sum = 0;
    weight = 11;

    ({ sum, weight } = this.accumulateWeightedValues(sum, numbers, weight));

    sum += digit1 * 2;
    remainder = sum % 11;
    let digit2 = remainder < 2 ? 0 : 11 - remainder;

    return [digit1, digit2];
  }
}
