import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ActionMenu } from '@/react/components/idp/shared/actions-menu.component';

interface SectionHeaderProps {
  title: string;
  onBackClicked: () => void;
  actions?: {
    label: string;
    onClick: () => void;
  }[];
}

const SectionHeader = ({
  title,
  onBackClicked,
  actions,
}: SectionHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton aria-label="voltar" size="small" onClick={onBackClicked}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h5">{title}</Typography>
      </Box>
      {actions?.length > 0 && <ActionMenu actions={actions} />}
    </Box>
  );
};

export { SectionHeader };
