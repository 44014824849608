import * as React from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/utils/money-parser';
import StepActions from '@/react/components/wizard/step-actions.component';
import { ResourceCourseSources, ResourceTypes } from '../../interfaces';
import ResourceApprovalCreatedDialog from '@/react/components/refund/refund-request/resource-approval-created-dialog.component';
import { calculateRefundableValueForPolicy } from '@/react/data/subsidy/calculateRefundableValueForPolicy';

export const RefundableValue = ({
  isValid,
  value,
}: {
  isValid: boolean;
  value: number;
}) => {
  return (
    <Box
      component="span"
      sx={({ palette }) => ({
        color: !isValid ? palette.error.main : 'inherit',
      })}
    >
      {formatMoney(value)}
    </Box>
  );
};

const formatCreateRefundRequest = ({ formValues, policyUuid, formType }) => {
  if (formType === ResourceCourseSources.EXTERNAL) {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        data: {
          course: {
            address: {
              campus: formValues.campus,
              city: formValues.city,
              state: formValues.state,
            },
            course_type: formValues.courseType,
            duration_type: formValues.durationType,
            duration: formValues.duration,
            id: formValues.course?.id,
            institution_name: formValues.institution,
            modality: formValues.modality,
            period: formValues.period,
            source: ResourceCourseSources.EXTERNAL,
            workload: formValues.workload,
          },
        },
        link: formValues.link,
        name: formValues.course,
        request_reason: formValues.requestReason,
        sale_type: formValues.salesType,
        type: ResourceTypes.COURSE,
      },
    };
  } else if (formType === ResourceCourseSources.CATALOG) {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        data: {
          course: {
            address: {
              campus: `${formValues.campus.label} - ${formValues.campus.value}`,
              city: formValues.city.value,
              state: formValues.state.value,
            },
            course_type: formValues.courseType,
            duration_type: formValues.durationType,
            duration: formValues.duration,
            id: formValues.course.id,
            institution_name: formValues.institution.name,
            modality: formValues.modality,
            offer_identifier: formValues.offerIdentifier,
            period: formValues.period.value,
            source: ResourceCourseSources.CATALOG,
            workload: formValues.workload,
          },
        },
        link: formValues.link,
        name: formValues.course.label,
        request_reason: formValues.requestReason,
        sale_type: formValues.salesType,
        type: ResourceTypes.COURSE,
      },
    };
  }
};

const initialState = {
  isSubmitting: false,
  resourceApprovalCreated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CREATED':
      return { ...state, resourceApprovalCreated: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

const RefundSummary = () => {
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const { actions: accordionActions } = useAccordion();
  const { loading, subsidyPolicy } = useRefundRequest();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const itemSource = wizardValues[WizardStepIds.ITEM_SOURCE_FORM] ?? '';
  const formValues =
    itemSource === ResourceCourseSources.EXTERNAL
      ? wizardValues[WizardStepIds.EXTERNAL_FORM]
      : wizardValues[WizardStepIds.CATALOG_FORM];

  const {
    isFetching: isFetchingRefundableValue,
    data: refundableValueResponse,
  } = calculateRefundableValueForPolicy({
    course: {
      checkout_type: 'internal',
      course_type: formValues.courseType,
      duration_type: formValues.durationType,
      duration: formValues.duration,
      id: formValues.course?.id,
      price: formValues.amount,
      type: formValues.salesType,
    },
  });

  const refundableValue =
    refundableValueResponse?.refundable_value?.amount ?? 0;
  const totalValueParsed = parseFloat(formValues.amount);
  const valid = !!refundableValue;
  const [resourceApprovalUUID, setResourceApprovalUUID] = React.useState(null);

  const handleCreateRefund = async () => {
    if (!valid) {
      return;
    }

    const requestPayload = formatCreateRefundRequest({
      formValues,
      policyUuid: subsidyPolicy.uuid,
      formType: itemSource,
    });

    dispatch({ type: 'SET_SUBMITTING', payload: true });
    dispatch({ type: 'SET_CREATED', payload: false });

    try {
      const resource =
        await SubsidyClient.createResourceApproval(requestPayload);

      if (!!resource) {
        setResourceApprovalUUID(resource.resource_approval.uuid);
        dispatch({ type: 'SET_SUBMITTING', payload: false });
        dispatch({ type: 'SET_CREATED', payload: true });
      }

      accordionActions.close(RefundRequestSteps.RESOURCE_DESCRIPTION);
    } catch (error) {
      console.error('Error creating refund request:', error);
      dispatch({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  const handleGoBack = () => {
    const destination =
      itemSource === ResourceCourseSources.EXTERNAL
        ? WizardStepIds.EXTERNAL_FORM
        : WizardStepIds.CATALOG_FORM;

    wizardActions.navigate(destination);
  };

  return (
    <>
      <Stack spacing={2} my={2}>
        <Typography component="div" variant="body3">
          Calculado de acordo com as regras de reembolso da sua política. O
          reembolso final depende também do seu saldo disponível.
        </Typography>
      </Stack>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          p: 2,
          mt: 3,
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Custo total do item
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {formatMoney(totalValueParsed)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor máximo reembolsável
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {isFetchingRefundableValue ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue isValid={valid} value={refundableValue} />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <StepActions>
        <LoadingButton color="primary" onClick={handleGoBack} variant="text">
          Voltar
        </LoadingButton>
        <LoadingButton
          color="primary"
          disabled={!valid}
          loading={state.isSubmitting}
          onClick={() => handleCreateRefund()}
          variant="contained"
        >
          Enviar para aprovação
        </LoadingButton>
      </StepActions>
      <ResourceApprovalCreatedDialog
        actionsVisibility={{
          waitApproval: !subsidyPolicy.configuration.auto_approval_enabled,
        }}
        open={state.resourceApprovalCreated}
        resourceApprovalUUID={resourceApprovalUUID}
      />
    </>
  );
};

export default RefundSummary;
