import {
  CatalogCourse,
  Institution,
  Offer,
  OfferWithDetails,
  ProductCourse,
} from '@/react/data/catalog/interfaces';
import {
  CourseEvent,
  EcommerceFragment,
  EnrollmentEvent,
  Event,
  ClickEvent,
  SubscriptionEvent,
  FinishSubscriptionEvent,
  CheckoutEvent,
  FinishCheckoutEvent,
  PurchaseEvent,
  ClickBannerOfferEvent,
} from './schemas';
import { CursoHorario } from '@/models/curso-horario.model';
import { z } from 'zod';
import CONST from '@/constants';
import { TrackClickBannerOfferEventParams } from './interfaces';

export class GoogleTagManager {
  dataLayer: any;
  userId: string;

  constructor() {
    this.dataLayer = this.initializeDataLayer();
    this.userId = this.getUserId();
  }

  private initializeDataLayer = () => {
    return window['dataLayer'] || [];
  };

  private getUserId = () => {
    return localStorage.getItem(CONST.USER_KEY) || 'default';
  };

  private categoriesKeys = [
    'item_category',
    'item_category2',
    'item_category3',
  ];

  private courseToEcommerce = (
    course: CatalogCourse,
    index?: number
  ): z.infer<typeof EcommerceFragment> => {
    const ecommercePayload = {
      item_name: course.course_name,
      item_id: course.id,
      price: course.price_with_discount,
      item_brand: course.institution.name,
      item_category4: course.duration.quantity,
      item_variant: course.modalities[0],
      item_list_name: course.course_type.label,
      index: index ?? 0,
    };

    const categories = course.knowledge_areas;
    categories?.map((category, index) => {
      if (this.categoriesKeys[index]) {
        ecommercePayload[this.categoriesKeys[index]] = category;
      }
    });

    const ecommerce = EcommerceFragment.safeParse(ecommercePayload);
    if (ecommerce.success) {
      return ecommerce.data;
    }
  };

  private productToEcommerce = (
    course: ProductCourse,
    offer: Offer,
    institution: Institution
  ): z.infer<typeof EcommerceFragment> => {
    const ecommercePayload = {
      item_name: course.name,
      item_id: course.id,
      price: offer.pricing_description.sale_price,
      item_brand: institution?.name,
      item_category4: course.duration.quantity,
      item_variant: course.modality.label,
      item_list_name: course.course_type.label,
      index: 0,
    };

    if (course.knowledge_areas) {
      Object.assign(ecommercePayload, {
        item_category: course.knowledge_areas[0],
        item_category2: course.knowledge_areas[1],
        item_category3: course.knowledge_areas[2],
      });
    }

    const ecommerce = EcommerceFragment.safeParse(ecommercePayload);
    if (ecommerce.success) {
      return ecommerce.data;
    }
  };

  courseHourToEcommerce = (
    course: CursoHorario
  ): z.infer<typeof EcommerceFragment> => {
    const courseDetail = course.curso_detalhe;
    if (!courseDetail) return;

    const institution = course.campus?.instituicao;

    const ecommercePayload = {
      item_name: courseDetail.name,
      item_id: courseDetail.id,
      price: course.sale_price,
      item_brand: institution?.holding_name,
      item_category4: course.duracao,
      item_variant: course.modalidade.modalidade,
      item_list_name: courseDetail.course_type,
      index: 0,
    };

    const categories = courseDetail.curso?.categorias;
    categories?.map((category, index) => {
      if (this.categoriesKeys[index]) {
        ecommercePayload[this.categoriesKeys[index]] = category.categoria;
      }
    });

    const ecommerce = EcommerceFragment.safeParse(ecommercePayload);
    if (ecommerce.success) {
      return ecommerce.data;
    }
  };

  trigger = (event: z.infer<typeof Event>) => {
    this.dataLayer.push(event);
  };

  trackCourseEvent = (
    course: ProductCourse,
    offer: Offer,
    institution: Institution
  ) => {
    const item = this.productToEcommerce(course, offer, institution);
    const event = CourseEvent.safeParse({
      event: 'view_item',
      user_id: this.userId,
      items: [item],
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackCoursesEvent = (courses: CatalogCourse[]) => {
    const items = courses.map((course, index) =>
      this.courseToEcommerce(course, index)
    );
    const event = CourseEvent.safeParse({
      event: 'view_item_list',
      user_id: this.userId,
      items,
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackSelectCourseEvent = (course: CatalogCourse) => {
    const item = this.courseToEcommerce(course);
    const event = CourseEvent.safeParse({
      event: 'select_item',
      user_id: this.userId,
      items: [item],
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackLoginEvent = () => {
    const event = ClickEvent.safeParse({
      event: 'login',
      user_id: this.userId,
      acao: 'click',
      botao: 'login',
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackEnrollmentEvent = (
    course: ProductCourse,
    offer: Offer,
    institution: Institution
  ) => {
    const item = this.productToEcommerce(course, offer, institution);
    const event = EnrollmentEvent.safeParse({
      event: 'add_to_cart',
      user_id: this.userId,
      acao: 'click',
      botao: 'iniciar_matricula',
      ecommerce: { items: [item] },
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackSubscriptionEvent = (course: CursoHorario) => {
    const item = this.courseHourToEcommerce(course);

    const event = SubscriptionEvent.safeParse({
      event: 'begin_checkout',
      user_id: this.userId,
      ecommerce: { items: [item] },
      bolsa: course.desconto ? course.desconto / 100 : 0,
      journey: 'subscription',
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackFinishSubscriptionEvent = (course: CursoHorario) => {
    const item = this.courseHourToEcommerce(course);
    const event = FinishSubscriptionEvent.safeParse({
      event: 'finish_subscription',
      user_id: this.userId,
      acao: 'click',
      botao: 'finalizar_inscricao',
      ecommerce: { items: [item] },
      bolsa: course.desconto ? course.desconto / 100 : 0,
      journey: 'subscription',
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackSubscriptionSuccessEvent = (course: CursoHorario, coupon) => {
    const item = this.courseHourToEcommerce(course);
    const event = PurchaseEvent.safeParse({
      event: 'purchase',
      user_id: this.userId,
      currency: 'BRL',
      value: item.price,
      tax: 0,
      coupon: coupon?.code,
      comission: 0,
      journey: 'subscription',
      ecommerce: { items: [item] },
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackCheckoutEvent = (offer: OfferWithDetails) => {
    if (!offer.course) return;

    const item = this.productToEcommerce(
      offer.course,
      offer,
      offer.institution
    );
    const event = CheckoutEvent.safeParse({
      event: 'begin_checkout',
      user_id: this.userId,
      offer_id: offer.id,
      journey: 'checkout',
      ecommerce: { items: [item] },
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackShippingInfoEvent = (offer: OfferWithDetails) => {
    if (!offer.course) return;
    const item = this.productToEcommerce(
      offer.course,
      offer,
      offer.institution
    );
    const event = CheckoutEvent.safeParse({
      event: 'add_shipping_info',
      user_id: this.userId,
      offer_id: offer.id,
      journey: 'checkout',
      ecommerce: { items: [item] },
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackPaymentInfoEvent = (offer: OfferWithDetails) => {
    if (!offer.course) return;

    const item = this.productToEcommerce(
      offer.course,
      offer,
      offer.institution
    );
    const event = CheckoutEvent.safeParse({
      event: 'add_payment_info',
      user_id: this.userId,
      offer_id: offer.id,
      journey: 'checkout',
      ecommerce: { items: [item] },
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackCompletePaymentEvent = (
    offer: OfferWithDetails,
    coupon,
    paymentMethod
  ) => {
    if (!offer.course) return;

    const item = this.productToEcommerce(
      offer.course,
      offer,
      offer.institution
    );
    const event = FinishCheckoutEvent.safeParse({
      event: 'purchase',
      user_id: this.userId,
      acao: 'click',
      botao: 'finalizar_compra',
      value: item.price,
      currency: 'BRL',
      tax: 0,
      coupon: coupon?.code,
      offer_id: offer.id,
      comission: 0,
      transaction_id: new Date().getTime(),
      journey: 'checkout',
      ecommerce: { items: [item] },
      payment_method: paymentMethod,
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };

  trackClickBannerOfferEvent = ({
    tagsGTM,
    link,
  }: TrackClickBannerOfferEventParams) => {
    const itemsPayload: z.infer<typeof ClickBannerOfferEvent.shape.items> = [
      {
        item_id: tagsGTM.id,
        item_name: tagsGTM.promotion_name,
      },
    ];

    const event = ClickBannerOfferEvent.safeParse({
      event: 'select_promotion',
      user_id: this.userId,
      creative_name: tagsGTM.creative_name,
      creative_slot: tagsGTM.creative_slot,
      promotion_id: link,
      promotion_name: tagsGTM.promotion_name,
      items: itemsPayload,
    });

    if (event.success) {
      this.trigger(event.data);
    }
  };
}
