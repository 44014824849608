import * as React from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { IconButton, Stack, Typography } from '@mui/material';
import CustomizedMenus from '@/react/components/refund/refund-header/menu-action.component';
import { useRefundRequest } from '../refund-request/state/useRefundRequest';

const RefundHeader = () => {
  const { resourceApproval } = useRefundRequest();

  return (
    <Stack direction="row" spacing={1} mb={4} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack alignItems="center" direction="row" gap={1}>
          <IconButton aria-label="voltar" size="small" href="/meus-reembolsos">
            <ArrowBackIosRoundedIcon 
              sx={({palette}) => ({ ':hover': { color: palette.primary[700] } })}
            />
          </IconButton>
          <Typography variant="h5">Pedido de reembolso</Typography>
        </Stack>
        {resourceApproval && <CustomizedMenus />}
    </Stack>
  );
};

export default RefundHeader;
