import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { ShowPoliciesProps } from './interfaces';
import { useSubsidyPolicies } from '@/react/data/subsidy/useSubsidyPolicies';
import { formatMoney } from "@/react/utils/money";

const ShowPolicies:React.FC<ShowPoliciesProps> = ({onclick}) => {

  const {
    isFetching: isFetchingPolicies,
    policy,
  } = useSubsidyPolicies();

  const refundbalance = Number(policy?.limits.amount_available);

  if (refundbalance === null) {
    return null;
  }


  return (
    <>
      <Box sx={({palette}) => ({
        maxHeight: '80px',
        width: { xs: '100%', lg: 'auto' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: palette.neutral[50],
        border:`1px solid ${palette.neutral[300]}`,
        p: 2,
      })}>
      {isFetchingPolicies && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}>
          <Skeleton variant="text" width={70} height={20} /><Skeleton variant="text" width={30} height={20} />
        </Box>
      )}
        {!isFetchingPolicies && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}>
            <Typography variant="body3" color="neutral.800" noWrap>
              Saldo disponível
            </Typography>
            <Typography variant="h6" color="success.700">
              {formatMoney(refundbalance)}
            </Typography>
          </Box>
        )}
        <Button
          color="primary"
          size="small"
          endIcon={<ArrowForward />}
          variant="text"
          onClick={onclick}
          sx={({palette})=>({
            borderRadius: 0,
            alignSelf: 'flex-end',
            textDecoration: 'underline',
            ':hover':{
              textDecoration: 'underline',
              color: palette.primary[900],
              backgroundColor: palette.primary[300],
            }
        })}>
          {refundbalance ? 'Ver regras de reembolso' : 'Ver regras'}
        </Button>
      </Box>
    </>
  );
};
export default ShowPolicies;
