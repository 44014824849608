import { DocumentValidator } from './DocumentValidator';

export class CNPJValidator extends DocumentValidator {
  private sumDigitsWithWeights(
    numbers: number[],
    acumulator: number,
    weight: number
  ) {
    for (let i = 0; i < numbers.length; i++) {
      acumulator += numbers[i] * weight--;
      if (weight < 2) weight = 9;
    }
    return { acumulator, weight };
  }
  protected getDocumentLength(): number {
    return 14;
  }

  protected getVerifierDigits(numbers: number[], document: string): number[] {
    let acumulator = 0;
    let weight = numbers.length - 7;

    ({ acumulator, weight } = this.sumDigitsWithWeights(
      numbers,
      acumulator,
      weight
    ));

    let firstDigitVerifier = acumulator % 11 < 2 ? 0 : 11 - (acumulator % 11);

    numbers.push(firstDigitVerifier);

    acumulator = 0;
    weight = numbers.length - 7;

    ({ acumulator, weight } = this.sumDigitsWithWeights(
      numbers,
      acumulator,
      weight
    ));

    let secondDigitVerifier = acumulator % 11 < 2 ? 0 : 11 - (acumulator % 11);

    return [firstDigitVerifier, secondDigitVerifier];
  }
}
