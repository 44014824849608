import React, { useEffect } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
} from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import { ConfirmStartPlanDialog } from '@/react/components/idp/dialogs/confirm-start-plan-dialog.component';
import { ConfirmDeletePlanDialog } from '@/react/components/idp/dialogs/confirm-delete-plan-dialog.component';

import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { IdpClient } from '@/react/data/idp/idp-client';
import { Activity, Plan, PlanStatuses } from '@/react/data/idp/interfaces';
import { grey } from '@mui/material/colors';
import { zodResolver } from '@hookform/resolvers/zod';
import { updatePlanSchema } from '@/react/data/idp/schemas';
import {
  EstimatedEndsAt,
  StartedAt,
  EndedAt,
} from '@/react/components/idp/show-plan/date-display.component';
import { SectionHeader } from '../shared/section-header.component';

import { useNotification } from '@/react/components/notification';
import { ShowPlanAction } from '@/react/components/idp/show-plan/show-plan-action';
import { ConfirmRestartPlanDialog } from '@/react/components/idp/dialogs/confirm-restart-plan-dialog.component';
import { InlineEditTextField } from '@/react/components/form/inline-edit-textfield.component';
import { ActivitiesList } from '@/react/components/idp/activities-list/activities-list';

enum EDITING_FIELD {
  TITLE = 'title',
  DESCRIPTION = 'description',
  DURATION_IN_MONTHS = 'duration_in_months',
}

const handleNewActivity = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}/atividades/nova`;
};

const handleBackToPlanList = () => {
  window.location.href = '/pdi/planos';
};

type ShowPlanProps = {
  planId: string;
};

const ActivitiesActions = ({ plan }: { plan: Plan }) => {
  return (
    <>
      <Grid item xs={6} className="media-screen">
        <Button onClick={() => handleNewActivity(plan.id)} variant="text">
          Adicionar atividade
        </Button>
      </Grid>

      <Grid item xs={6} className="media-screen">
        <Button variant="text">Reordenar</Button>
      </Grid>
    </>
  );
};

const ShowPlan = ({ planId }: ShowPlanProps) => {
  const [plan, setPlan] = React.useState<Plan>(null);
  const [activities, setActivities] = React.useState<Activity[]>(null);
  const [editingField, setEditingField] = React.useState<EDITING_FIELD>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDeletePlanModalOpen, setIsDeletePlanModalOpen] =
    React.useState(false);
  const inputRefs = {
    [EDITING_FIELD.TITLE]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DESCRIPTION]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DURATION_IN_MONTHS]: React.useRef<HTMLInputElement>(null),
  };
  const [isRestartPlanDialogOpen, setIsRestartPlanDialogOpen] =
    React.useState(false);
  const { toast } = useNotification();

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: zodResolver(updatePlanSchema),
  });

  const fetchPlan = async () => {
    try {
      const fetchedPlan = await IdpClient.showPlan(planId);
      const fetchedPlanActivities = await IdpClient.listPlanActivities(planId);
      setPlan(fetchedPlan);
      setActivities(fetchedPlanActivities);
    } catch (error) {
      Sentry.captureException(error);
      window.location.href = '/pdi/planos';
    }
  };

  const handleChange = () => {
    handleSubmit(async () => {
      try {
        const updatedPlan = await IdpClient.updatePlan(planId, getValues());
        setPlan(updatedPlan);
        setEditingField(null);
      } catch (error) {
        Sentry.captureException(error);
        setEditingField(null);
      }
    })();
  };

  const mountDurationOptions = () => {
    const options = [];
    for (let i = 1; i < 19; i += 1) {
      options.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return options;
  };

  const handleEditButton = (field: EDITING_FIELD) => {
    setEditingField(field);
  };

  const handleCanStartPlan = async (): Promise<boolean> => {
    const plans: Plan[] = await IdpClient.plans({
      search: { status: 'in_progress' },
    });

    if (plans.length) {
      const errorMessage =
        'Só é possível ter um plano em andamento por vez. Para iniciar esse, finalize o plano em andamento.';
      toast({
        content: <Typography variant="body2">{errorMessage}</Typography>,
        type: 'error',
      });
      return false;
    }

    return true;
  };

  const handleOpenRestartPlanDialog = async () => {
    if (!(await handleCanStartPlan())) return;

    setIsRestartPlanDialogOpen(true);
  };

  const actions = [
    {
      label: 'Excluir plano',
      onClick: () => {
        setIsDeletePlanModalOpen(true);
      },
    },
  ];

  if (plan?.status == PlanStatuses.CLOSED) {
    actions.push({
      label: 'Reiniciar plano',
      onClick: () => {
        handleOpenRestartPlanDialog();
      },
    });
  }

  useEffect(() => {
    if (planId) {
      fetchPlan();
    }
  }, [planId]);

  useEffect(() => {
    setValue('title', plan?.title);
    setValue('description', plan?.description);
    setValue('duration_in_months', plan?.durationInMonths);
  }, [plan]);

  useEffect(() => {
    inputRefs[editingField]?.current?.focus();
  }, [editingField]);

  const handleInlineEditFieldsChange =
    (field: ControllerRenderProps<FieldValues, any>) =>
    async (value: string) => {
      try {
        handleChange();
        field.onChange(value);
        return true;
      } catch (error) {
        return false;
      }
    };

  return (
    plan && (
      <Container fixed maxWidth="xl">
        <Grid container spacing={2} py={3}>
          <Grid item xs={12}>
            <SectionHeader
              title="Plano de desenvolvimento"
              onBackClicked={handleBackToPlanList}
              actions={actions}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" fontSize={18} fontWeight={600}>
              Título do passo
            </Typography>
            <Typography variant="body2" fontSize={14}>
              [Descrição do passo]
            </Typography>
          </Grid>

          <Grid className="media-screen" item xs={12}>
            <Button onClick={() => window.print()} variant="outlined">
              Exportar em PDF
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="title"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <FormLabel htmlFor="title">
                    <Typography variant="h6" fontSize={18} fontWeight={600}>
                      Título
                    </Typography>
                  </FormLabel>
                  <InlineEditTextField
                    value={plan.title}
                    onAccept={handleInlineEditFieldsChange(field)}
                    customOnBlur={handleInlineEditFieldsChange(field)}
                    error={!!error}
                    disabled={plan.status === PlanStatuses.CLOSED}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <FormLabel htmlFor="description">
                    <Box component="span" sx={{ pl: 1, fontWeight: 400 }}>
                      <Typography
                        variant="h6"
                        fontSize={18}
                        fontWeight={600}
                        display="flex"
                      >
                        Descrição
                      </Typography>
                    </Box>
                  </FormLabel>
                  <InlineEditTextField
                    value={plan.description}
                    onAccept={handleInlineEditFieldsChange(field)}
                    customOnBlur={handleInlineEditFieldsChange(field)}
                    error={!!error}
                    disabled={plan.status === PlanStatuses.CLOSED}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <StartedAt plan={plan} />
          </Grid>

          <Grid item xs={12}>
            {editingField === EDITING_FIELD.DURATION_IN_MONTHS ||
            !plan.durationInMonths ? (
              <Controller
                name="duration_in_months"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="duration_in_months">
                        Duração em meses
                      </FormLabel>

                      <Select
                        {...field}
                        variant="outlined"
                        displayEmpty
                        id="duration_in_months"
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange();
                        }}
                        renderValue={(value) =>
                          value || (
                            <Typography color={grey[400]} fontSize={14}>
                              [Duração em meses]
                            </Typography>
                          )
                        }
                      >
                        {mountDurationOptions()}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            ) : (
              <>
                <Typography variant="h6" fontSize={18} fontWeight={600}>
                  Duração em meses
                </Typography>

                <IconButton
                  sx={{ paddingLeft: 0 }}
                  onClick={() =>
                    handleEditButton(EDITING_FIELD.DURATION_IN_MONTHS)
                  }
                >
                  <Box display="flex">
                    <Typography>{plan.durationInMonths}</Typography>
                    <EditIcon fontSize="small" sx={{ marginLeft: 2 }} />
                  </Box>
                </IconButton>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <EstimatedEndsAt plan={plan} />
          </Grid>
          <Grid item xs={12}>
            <EndedAt plan={plan} />
          </Grid>

          <Grid item xs={12}>
            <ActivitiesList plan={plan} activities={activities} />
          </Grid>

          <Grid item xs={12}>
            <ShowPlanAction plan={plan} />
          </Grid>

          <ConfirmStartPlanDialog
            plan={plan}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
          <ConfirmDeletePlanDialog
            plan={plan}
            open={isDeletePlanModalOpen}
            onClose={() => setIsDeletePlanModalOpen(false)}
          />

          <ConfirmRestartPlanDialog
            plan={plan}
            open={isRestartPlanDialogOpen}
            onClose={() => setIsRestartPlanDialogOpen(false)}
          />
        </Grid>
      </Container>
    )
  );
};

export { ShowPlan };
