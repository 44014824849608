import * as React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { banksSelectOptions } from '@/react/data/bank';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import StepActions from '@/react/components/wizard/step-actions.component';
import { MoneyField } from '@/react/components/form';
import {
  Months,
  ProfOfPayment,
  ProofOfPurchase,
  OtherReceiptType,
  SupportType,
  TypeKeyPix,
  TypeOfBankTransfer,
} from '../interfaces';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import FileUpload from './file-upload.component';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import { formatFormValues } from './formValuesHelper';

import { DateTime } from 'luxon';
import { CustomDatePicker } from '@/react/components/date-picker';
import { CNPJValidator, CPFValidator } from './validators';

const parseDate = (dateString: string) => {
  if (!dateString) return '';
  const parsedDate = DateTime.fromISO(dateString, {
    locale: 'pt-BR',
  });

  return parsedDate.isValid ? parsedDate : null;
};

const NextButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    float: 'right',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const defaultValues = {
  supplierDocument: '',
  typeOfReceiptPurchase: '',
  typeOfReceiptPayment: '',
  referenceMonth: '',
  paymentDate: '',
  profOfPaymentPaymentDate: '',
  profOfPaymentAmountPaid: '',
  paymentAmount: '',
  holderName: '',
  documentEmployeer: '',
  documentEmployeerText: '',
  supplierName: '',
  supplierType: '',
  typeOfBankTransfer: '',
  bank: '',
  pixKey: '',
  pixType: '',
  accountNumber: '',
  agency: '',
  documentsProvingPurchase: [],
  documentsProvingPayment: [],
  optionalFiles: [],
};

const mainValidationSchema = z.object({
  holderName: z
    .string()
    .min(1, { message: 'Nome é obrigatório.' })
    .regex(/^\S+\s+\S+.*$/, {
      message: 'Por favor, insira o nome completo (nome e sobrenome).',
    })
    .refine(
      (value) => {
        const parts = value.trim().split(/\s+/);
        return parts.length >= 2 && parts.every((part) => part.length > 1);
      },
      {
        message: 'Cada parte do nome deve ter pelo menos 2 caracteres.',
      }
    ),
  typeOfReceiptPurchase: z.string().min(1, 'Tipo de comprovante obrigatório'),
  typeOfReceiptPayment: z.string().min(1, 'Tipo de comprovante obrigatório'),
  profOfPaymentPaymentDate: z.string().superRefine((value, ctx) => {
    const [day, month, year] = value.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Data inválida.',
      });
    }
  }),
  paymentDate: z.string().superRefine((value, ctx) => {
    const [day, month, year] = value.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Data inválida.',
      });
    }
  }),
  profOfPaymentAmountPaid: z.coerce
    .number()
    .min(1, 'Valor da mensalidade obrigatório')
    .positive('Valor deve ser maior que zero'),
  paymentAmount: z.coerce
    .number()
    .min(1, 'Valor pago obrigatório')
    .positive('Valor deve ser maior que zero'),
  supplierName: z.string().min(1, 'Nome do fornecedor obrigatório'),
});

const cnpjSchema = z
  .string()
  .refine((value) => /^\d{14}$/.test(value.replace(/\D/g, '')), {
    message: 'CNPJ deve conter 14 dígitos numéricos.',
  })
  .transform((value) => value.replace(/\D/g, ''))
  .refine((cnpj) => new CNPJValidator().validateDocument(cnpj), {
    message: 'CNPJ inválido.',
  });

const cpfSchema = z
  .string()
  .refine((value) => /^\d{11}$/.test(value.replace(/\D/g, '')), {
    message: 'CPF deve conter 11 dígitos numéricos.',
  })
  .transform((value) => value.replace(/\D/g, ''))
  .refine((cpf) => new CPFValidator().validateDocument(cpf), {
    message: 'CPF inválido.',
  });

const validateCPFSchema = z.object({
  documentEmployeer: cpfSchema,
});

const validateCNPJCPFSchema = z
  .object({
    supplierType: z.nativeEnum(SupportType, {
      errorMap: () => {
        return { message: 'Tipo de pagamento é obrigatório' };
      },
    }),
    supplierDocument: z.string().transform((value) => value.replace(/\D/g, '')),
  })
  .partial()
  .superRefine((values, ctx) => {
    if (values.supplierType === SupportType.natural_person) {
      const cpfValidationResult =
        validateCPFSchema.shape.documentEmployeer.safeParse(
          values.supplierDocument
        );

      if (!cpfValidationResult.success) {
        const message = cpfValidationResult.error.format()._errors[0];

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['supplierDocument'],
          message,
        });
      }
    } else if (values.supplierType === SupportType.legal_person) {
      const cnpjValidationResult = cnpjSchema.safeParse(
        values.supplierDocument
      );
      if (!cnpjValidationResult.success) {
        const message = cnpjValidationResult.error.format()._errors[0];
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['supplierDocument'],
          message,
        });
      }
    }
  });

const bankSchema = z
  .object({
    pixType: z.string(),
    pixKey: z.string(),
    accountNumber: z.string(),
    agency: z.string(),
    bank: z.string(),
    typeOfBankTransfer: z.string().min(1, 'Tipo de pagamento obrigatório'),
  })
  .partial()
  .superRefine(
    (
      { pixType, pixKey, accountNumber, agency, bank, typeOfBankTransfer },
      ctx
    ) => {
      if (TypeOfBankTransfer.ted === typeOfBankTransfer) {
        if (!bank) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Banco obrigatório',
            path: ['bank'],
          });
        }

        if (!accountNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Conta obrigatória',
            path: ['accountNumber'],
          });
        }
        if (!agency) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Agência obrigatória',
            path: ['agency'],
          });
        }
      }

      if (
        [TypeOfBankTransfer.pix].includes(
          typeOfBankTransfer as TypeOfBankTransfer
        )
      ) {
        if (!pixType) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Tipo de chave obrigatório',
            path: ['pixType'],
          });
        }

        if (!pixKey) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Chave obrigatória',
            path: ['pixKey'],
          });
        }

        pixKeyValidation(pixType, pixKey, ctx);
      }
    }
  );

const MAX_MB_FILE_SIZE = 2;

// zod has a limitation with dependent fields, so it's best to have them in separate uploadFilesSchema
// more about this: https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005
const uploadFilesSchema = z.object({
  documentsProvingPurchase: z
    .array(z.instanceof(File))
    .refine((file) => file?.length > 0, 'Pelo menos um arquivo é necessário.')
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2; // Convertendo para MB
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE; // Verificando se é maior que 2MB
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
  documentsProvingPayment: z
    .array(z.instanceof(File))
    .refine((file) => file?.length > 0, 'Pelo menos um arquivo é necessário.')
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2;
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE;
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
  optionalFiles: z
    .array(z.instanceof(File))
    .optional()
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2;
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE;
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
});

const referenceMonthSchema = (resourceApproval) => {
  return z.object({
    referenceMonth: z.string().superRefine((value, ctx) => {
      if (resourceApproval.sale_type === PriceTypes.tuition && !value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Mês de referência obrigatório',
        });
      }
    }),
  });
};

const pixKeyPlaceHolder = (pixType) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      return 'Ex.: 000.000.000-00';
    case TypeKeyPix.email:
      return 'Ex.: email@email.com';
    case TypeKeyPix.phone:
      return 'Ex.: (00) 00000-0000';
    case TypeKeyPix.random:
      return 'Ex.: 00000000-0000-0000-0000-000000000000';
    default:
      return '';
  }
};

const pixKeyValidation = (pixType, pixKey, ctx) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      if (!cpfSchema.safeParse(pixKey).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'CPF inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.email:
      if (!z.string().email().safeParse(pixKey).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Email inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.phone:
      if (
        !z
          .string()
          .regex(
            /\(?\b([0-9]{2,3}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/
          )
          .safeParse(pixKey).success
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Número de telefone inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.random:
      if (
        !z
          .string()
          .regex(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
          )
          .safeParse(pixKey).success
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Chave aleatória inválida',
          path: ['pixKey'],
        });
      }
    default:
      return false;
  }
};

const pixKeyFormat = (pixType, pixKey) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      return pixKey.replace(/[^\d]+/g, '');
    case TypeKeyPix.phone:
      return pixKey.replace(/[^\d]+/g, '');
    default:
      return pixKey;
  }
};

// z.intersection does not work to merge schemas here because
// we're using .refine/.superRefine which outputs ZodEffects instead of ZodObject.
// This is poorly documented in the zod documentation.
// As a workaround, we're using .and to merge the schemas.
// More about this:
// https://github.com/colinhacks/zod/issues/2474
// https://github.com/colinhacks/zod/issues/1147
const formValidationSchema = mainValidationSchema
  .and(validateCPFSchema)
  .and(validateCNPJCPFSchema)
  .and(bankSchema)
  .and(uploadFilesSchema);

const FieldSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Typography component="div" variant="body3">
        <Skeleton width="20%" />
      </Typography>
      <Skeleton height={50} variant="rounded" />
    </Stack>
  );
};

const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};

const SendingReceiptsForm: React.FC = () => {
  const { resourceApproval } = useRefundRequest();
  const { actions, values } = useWizard();
  const formValues = values[WizardStepIds.SENDING_RECEIPTS_FORM];

  const isFirstLoad = React.useRef(true);

  const bootstrapData = useBootstrapData();
  const loadingBootstrapData = bootstrapData.isFetching;

  const { control, handleSubmit, reset, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(
      formValidationSchema.and(referenceMonthSchema(resourceApproval))
    ),
  });

  React.useEffect(() => {
    const fetchAndFormatValues = async () => {
      const formattedValues = await formatFormValues(
        resourceApproval,
        formValues,
        defaultValues
      );

      // Form reset only on first load
      if (isFirstLoad.current) {
        reset(formattedValues);
        isFirstLoad.current = false;
      }
    };

    fetchAndFormatValues();
  }, [resourceApproval, formValues, reset]);

  const { supplierType, typeOfBankTransfer, pixType } = watch();
  const documentEmployeerValue = watch('documentEmployeer');

  const createReceiptsStructure = async (
    typeOfReceiptPurchase,
    documentsProvingPurchase,
    typeOfReceiptPayment,
    documentsProvingPayment,
    optionalFiles = []
  ) => {
    const combinedDocuments = [
      ...documentsProvingPurchase.map((file) => ({
        receipt_type: typeOfReceiptPurchase,
        attachment: file,
      })),
      ...documentsProvingPayment.map((file) => ({
        receipt_type: typeOfReceiptPayment,
        attachment: file,
      })),
      ...optionalFiles.map((file) => ({
        receipt_type: OtherReceiptType,
        attachment: file,
      })),
    ];

    return combinedDocuments;
  };

  const handleNext = async (data) => {
    const formData = { ...data };
    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const receipts = await createReceiptsStructure(
      formData.typeOfReceiptPurchase,
      formData.documentsProvingPurchase,
      formData.typeOfReceiptPayment,
      formData.documentsProvingPayment,
      formData.optionalFiles
    );
    formData.pixKey = pixKeyFormat(formData.pixType, formData.pixKey);

    const payload = {
      ...defaultValues,
      ...formData,
      receipts: receipts,
      resource_approval_uuid: resourceApproval.uuid,
    };

    actions.updateValues({
      [WizardStepIds.SENDING_RECEIPTS_FORM]: payload,
    });
    actions.navigate(WizardStepIds.SENDING_RECEIPTS_SUMMARY);
  };

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const handleInputChangeCPF = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length <= 11) {
      value = value
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }

    e.target.value = value;
  };

  const handleInputChangeCNPJ = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length <= 14) {
      value = value
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }

    e.target.value = value;
  };

  const handleInputChangeSupplierDocument = (e, supplierType) => {
    if (supplierType === SupportType.natural_person) {
      handleInputChangeCPF(e);
    } else if (supplierType === SupportType.legal_person) {
      handleInputChangeCNPJ(e);
    }
  };

  const handleInputChangePhoneNumber = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length <= 11) {
      value = value
        .replace(/^(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    }

    e.target.value = value;
  };

  const handlePixKeyInputChange = (e, pixType) => {
    switch (pixType) {
      case TypeKeyPix.cpf:
        return handleInputChangeCPF(e);
      case TypeKeyPix.phone:
        return handleInputChangePhoneNumber(e);
      default:
        return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <Grid container spacing={3}>
          {resourceApproval?.status ===
            RefundRequestStatus.revision_required && (
            <>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Alert
                    severity="warning"
                    sx={(theme) => {
                      return {
                        '.MuiAlert-icon': {
                          alignSelf: 'center',
                          color: '#886400',
                        },
                        '&& *': {
                          fontSize: theme.typography.body3.fontSize,
                        },
                      };
                    }}
                  >
                    <AlertTitle style={{ fontWeight: '600', marginBottom: 0 }}>
                      Correção necessária
                    </AlertTitle>
                    {resourceApproval?.refund_request?.decision_comment}
                  </Alert>
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Typography
                variant="body1"
                style={{ fontWeight: '600' }}
                color="primary.200"
              >
                Dados do fornecedor
              </Typography>
              <Typography variant="body3">
                É a empresa ou pessoa que fez a venda do item.
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="supplierName"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="supplierName">
                        Nome do fornecedor
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="supplierName"
                        inputProps={{ 'data-testid': 'supplierName-input' }}
                        placeholder="Ex: Nome da empresa"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="supplierType"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Stack spacing={1}>
                      <FormLabel id="supplierType">
                        Tipo de fornecedor
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        aria-labelledby="supplierType"
                        onChange={(e) => {
                          {
                            field.onChange(e.target.value);
                            trigger(['supplierDocument']);
                          }
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              size="small"
                              inputProps={
                                {
                                  'data-testid': 'supplierType-radio1',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                              }
                            />
                          }
                          label={
                            <Typography variant="body3">
                              Empresa / Pessoa jurídica
                            </Typography>
                          }
                          value={SupportType.legal_person}
                        />
                        <FormControlLabel
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="body3">
                              Pessoa física
                            </Typography>
                          }
                          value={SupportType.natural_person}
                        />
                      </RadioGroup>
                    </Stack>
                    <FormHelperText>{handleErrormessage(error)}</FormHelperText>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="supplierDocument"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="supplierDocument">
                          {supplierType === SupportType.natural_person
                            ? 'CPF'
                            : 'CNPJ do fornecedor'}
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          id="supplierDocument"
                          helperText={handleErrormessage(error)}
                          inputProps={{
                            'data-testid': 'supplierDocument-input',
                          }}
                          onChange={(e) => {
                            handleInputChangeSupplierDocument(e, supplierType);
                            field.onChange(e.target.value);
                          }}
                          placeholder={
                            supplierType === SupportType.legal_person
                              ? 'Ex.: 00.000.000/0001-00'
                              : 'Ex.: 000.000.000-00'
                          }
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Typography
                variant="body1"
                style={{ fontWeight: '600' }}
                color="primary.200"
              >
                Comprovante de compra
              </Typography>
              <Typography variant="body3">
                É o documento que o fornecedor emite após confirmação do
                pagamento. Pode ser uma nota fiscal, boleto ou recibo. Para
                compras internacionais você pode usar invoice.
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="documentEmployeer"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="documentEmployeer">
                        CPF do comprador
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        id="documentEmployeer"
                        helperText={handleErrormessage(error)}
                        inputProps={{
                          'data-testid': 'documentEmployeer-input',
                        }}
                        onChange={(e) => {
                          handleInputChangeCPF(e);
                          field.onChange(e.target.value);
                        }}
                        placeholder="Ex.: 012.345.678-90"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={4}>
            <Stack spacing={1}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="typeOfReceiptPurchase"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel id="typeOfReceiptPurchase">
                          Tipo de comprovante
                        </FormLabel>
                        <Select
                          {...field}
                          data-testid="typeOfReceiptPurchase-select"
                          displayEmpty={true}
                          error={!!error}
                          labelId="typeOfReceiptPurchase"
                          renderValue={(value) => {
                            return value
                              ? ProofOfPurchase[
                                  value as keyof typeof ProofOfPurchase
                                ]
                              : 'Selecione';
                          }}
                          variant="outlined"
                        >
                          {Object.entries(ProofOfPurchase).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Stack>
                      <FormHelperText>
                        {handleErrormessage(error)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="paymentDate"
                render={({ field, fieldState: { error } }) => {
                  const dateValue = parseDate(field.value);
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="paymentDate">
                          Data do pagamento
                        </FormLabel>
                        <CustomDatePicker
                          field={field}
                          value={
                            dateValue && dateValue.isValid ? dateValue : null
                          }
                          slotProps={{
                            textField: {
                              id: 'paymentDate',
                              error: !!error,
                              helperText: handleErrormessage(error),
                              inputProps: {
                                'data-testid': 'paymentDate-input',
                              },
                              variant: 'outlined',
                              placeholder: `Ex.: ${DateTime.now().toFormat('dd/MM/yy')}`,
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="paymentAmount"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="paymentAmount">
                        Valor pago (R$)
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        id="paymentAmount"
                        helperText={handleErrormessage(error)}
                        inputProps={{
                          currency: 'BRL',
                          'data-testid': 'paymentAmount-input',
                        }}
                        InputProps={{
                          inputComponent: MoneyField as any,
                          startAdornment: (
                            <InputAdornment disableTypography position="start">
                              R$
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Ex.: R$ 100,00"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                name="documentsProvingPurchase"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1} alignItems={'start'}>
                      <FormLabel htmlFor="documentsProvingPurchase">
                        Anexos
                      </FormLabel>
                      <FileUpload
                        {...field}
                        required={true}
                        onFilesChange={(files) => field.onChange(files)}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="documentsProvingPurchase"
                        defaultFiles={field.value || []}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Typography
                variant="body1"
                style={{ fontWeight: '600' }}
                color="primary.200"
              >
                Comprovante de pagamento
              </Typography>
              <Typography variant="body3">
                É o documento emitido pelo banco comprovando o pagamento. Pode
                ser a fatura do cartão ou imagem do comprovante no aplicativo
                bancário. Só serão aceitos documentos que possuam data da
                compra, valor e nome do pagador.
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="typeOfReceiptPayment"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel id="typeOfReceiptPayment">
                          Tipo de comprovante
                        </FormLabel>
                        <Select
                          {...field}
                          data-testid="typeOfReceiptPayment-select"
                          displayEmpty={true}
                          error={!!error}
                          labelId="typeOfReceiptPayment"
                          renderValue={(value) => {
                            return value
                              ? ProfOfPayment[
                                  value as keyof typeof ProfOfPayment
                                ]
                              : 'Selecione';
                          }}
                          variant="outlined"
                        >
                          {Object.entries(ProfOfPayment).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                      <FormHelperText>
                        {handleErrormessage(error)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={4}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="profOfPaymentPaymentDate"
                render={({ field, fieldState: { error } }) => {
                  const dateValue = parseDate(field.value);
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="profOfPaymentPaymentDate">
                          Data do pagamento
                        </FormLabel>
                        <CustomDatePicker
                          field={field}
                          value={
                            dateValue && dateValue.isValid ? dateValue : null
                          }
                          slotProps={{
                            textField: {
                              id: 'profOfPaymentPaymentDate',
                              error: !!error,
                              helperText: handleErrormessage(error),
                              inputProps: {
                                'data-testid': 'profOfPaymentPaymentDate-input',
                              },
                              variant: 'outlined',
                              placeholder: `Ex.: ${DateTime.now().toFormat('dd/MM/yy')}`,
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="profOfPaymentAmountPaid"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="profOfPaymentAmountPaid">
                        Valor da mensalidade (R$)
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        id="profOfPaymentAmountPaid"
                        helperText={handleErrormessage(error)}
                        inputProps={{
                          currency: 'BRL',
                          'data-testid': 'profOfPaymentAmountPaid-input',
                        }}
                        InputProps={{
                          inputComponent: MoneyField as any,
                          startAdornment: (
                            <InputAdornment disableTypography position="start">
                              R$
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Ex.: R$ 100,00"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          {resourceApproval.sale_type === PriceTypes.tuition && (
            <>
              <Grid item xs={12} sm={4}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="referenceMonth"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel id="referenceMonth">
                            Mês de referência
                          </FormLabel>
                          <Select
                            {...field}
                            error={!!error}
                            data-testid="referenceMonth-select"
                            displayEmpty={true}
                            labelId="referenceMonth"
                            renderValue={(value) => {
                              return value &&
                                Months[value as keyof typeof Months]
                                ? Months[value as keyof typeof Months]
                                : 'Selecione';
                            }}
                            variant="outlined"
                          >
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {Object.entries(Months).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                name="documentsProvingPayment"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1} alignItems={'start'}>
                      <FormLabel htmlFor="documentsProvingPayment">
                        Anexos
                      </FormLabel>
                      <FileUpload
                        {...field}
                        required={true}
                        onFilesChange={(files) => field.onChange(files)}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="documentsProvingPayment"
                        defaultFiles={field.value || []}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Typography
                variant="body1"
                style={{ fontWeight: '600' }}
                color="primary.200"
              >
                Outros comprovantes (Opcional)
              </Typography>
              <Typography variant="body3">
                Utilize esse espaço para adicionar comprovantes adicionais
                quando solicitados.
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sx={{ padding: 0 }}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                name="optionalFiles"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1} alignItems={'start'}>
                      <FileUpload
                        {...field}
                        required={false}
                        onFilesChange={(files) => field.onChange(files)}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="optionalFiles"
                        defaultFiles={field.value || []}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Typography variant="h6" color="primary.900">
                Dados bancários
              </Typography>
              <Typography variant="body3">
                Informe os dados da conta onde deseja receber o reembolso. A
                conta precisa estar no seu nome.
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="typeOfBankTransfer"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Stack spacing={1}>
                      <FormLabel id="typeOfBankTransfer">
                        Como deseja receber seu reembolso?
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        aria-labelledby="typeOfBankTransfer"
                        onChange={(e) => {
                          {
                            field.onChange(e.target.value);
                            if (e.target.value === TypeOfBankTransfer.pix) {
                              trigger(['pixType', 'pixKey']);
                            } else if (
                              e.target.value === TypeOfBankTransfer.ted
                            ) {
                              trigger(['bank', 'agency', 'accountNumber']);
                            }
                          }
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              size="small"
                              inputProps={
                                {
                                  'data-testid': 'typeOfBankTransfer-radio1',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                              }
                            />
                          }
                          label={<Typography variant="body3">PIX</Typography>}
                          value={TypeOfBankTransfer.pix}
                        />
                        <FormControlLabel
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="body3">
                              TED / Transferência Bancária
                            </Typography>
                          }
                          value={TypeOfBankTransfer.ted}
                        />
                      </RadioGroup>
                    </Stack>
                    <FormHelperText>{handleErrormessage(error)}</FormHelperText>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="holderName"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="holderName">
                          Nome completo
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="holderName"
                          placeholder="Ex.: Nome Completo"
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="documentEmployeerText"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="documentEmployeerText">
                          CPF
                        </FormLabel>
                        <TextField
                          {...field}
                          disabled
                          value={documentEmployeerValue}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="documentEmployeerText"
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>

          {[TypeOfBankTransfer.pix].includes(
            typeOfBankTransfer as TypeOfBankTransfer
          ) && (
            <>
              <Grid item xs={12}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="pixType"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <Stack spacing={1}>
                          <FormLabel id="pixType">Tipo de chave PIX</FormLabel>
                          <RadioGroup
                            {...field}
                            aria-labelledby="pixType"
                            onChange={(e) => {
                              {
                                field.onChange(e.target.value);
                                trigger(['pixKey']);
                              }
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  inputProps={
                                    {
                                      'data-testid': 'pixType-radio1',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                  }
                                />
                              }
                              label={
                                <Typography variant="body3">CPF</Typography>
                              }
                              value={TypeKeyPix.cpf}
                            />
                            <FormControlLabel
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body3">Email</Typography>
                              }
                              value={TypeKeyPix.email}
                            />
                            <FormControlLabel
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body3">
                                  Telefone
                                </Typography>
                              }
                              value={TypeKeyPix.phone}
                            />
                            <FormControlLabel
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body3">
                                  Chave aleatória
                                </Typography>
                              }
                              value={TypeKeyPix.random}
                            />
                          </RadioGroup>
                        </Stack>
                        <FormHelperText>
                          {handleErrormessage(error)}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="pixKey"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="pixKey">Chave PIX</FormLabel>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handlePixKeyInputChange(e, pixType);
                              field.onChange(e.target.value);
                            }}
                            error={!!error}
                            id="pixKey"
                            helperText={handleErrormessage(error)}
                            inputProps={{
                              'data-testid': 'pixKey-input',
                            }}
                            placeholder={pixKeyPlaceHolder(pixType)}
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
            </>
          )}

          {[TypeOfBankTransfer.ted].includes(
            typeOfBankTransfer as TypeOfBankTransfer
          ) && (
            <>
              <Grid item xs={12} sm={12}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="bank"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="bank">Banco</FormLabel>
                          <Autocomplete
                            {...field}
                            data-testid="bank-autocomplete"
                            id="bank"
                            options={banksSelectOptions.banks}
                            getOptionKey={(option) => option.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={handleErrormessage(error)}
                                placeholder="Selecione"
                              />
                            )}
                            onChange={(_, data, reason) => {
                              if (reason === 'clear') {
                                field.onChange(defaultValues.bank);
                              } else if (data) {
                                field.onChange(data.value);
                              }
                            }}
                            value={
                              banksSelectOptions.banks.find(
                                (option) => option.value === field.value
                              ) ?? null
                            }
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="agency"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="agency">Agência</FormLabel>
                          <TextField
                            {...field}
                            error={!!error}
                            id="agency"
                            inputProps={{ 'data-testid': 'agency-input' }}
                            placeholder="0001"
                            helperText={handleErrormessage(error)}
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="accountNumber"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="accountNumber">
                            Número da conta
                          </FormLabel>
                          <TextField
                            {...field}
                            error={!!error}
                            id="accountNumber"
                            helperText={handleErrormessage(error)}
                            inputProps={{
                              'data-testid': 'accountNumber-input',
                            }}
                            placeholder="01234-5"
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Grid>
            </>
          )}
        </Grid>

        <StepActions>
          <NextButton color="primary" type="submit" variant="contained">
            Próximo
          </NextButton>
        </StepActions>
      </form>
    </>
  );
};

export default SendingReceiptsForm;
