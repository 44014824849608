export abstract class DocumentValidator {
  protected abstract getDocumentLength(): number;

  protected abstract getVerifierDigits(
    numbers: number[],
    document: string
  ): number[];

  public validateDocument(document: string): boolean {
    document = document.replace(/[^\d]+/g, '');

    if (document.length !== this.getDocumentLength()) {
      return false;
    }

    if (/^(\d)\1{10,13}$/.test(document)) {
      return false;
    }

    const digits = document
      .split('')
      .map((char) => parseInt(char, 10))
      .slice(0, this.getDocumentLength() - 2);

    const verifierDigits = this.getVerifierDigits(digits, document);

    return document.slice(-2) === verifierDigits.join('');
  }
}
