import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ConfirmStartPlanDialog } from '@/react/components/idp/dialogs/confirm-start-plan-dialog.component';
import { ConfirmFinishPlanDialog } from '@/react/components/idp/dialogs/confirm-finish-plan-dialog.component';
import { Plan, PlanStatuses } from '@/react/data/idp/plans';

const ShowDraftPlanAction = ({ plan }: { plan: Plan }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Grid item xs={12} mt={2} className="media-screen">
        <Button
          fullWidth
          onClick={() => setIsModalOpen(true)}
          variant="contained"
        >
          Iniciar plano
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          Inicie o plano para poder atualizar e acompanhar o progresso das
          atividades. Não se preocupe, você ainda poderá editar o plano como
          quiser.
        </Typography>
      </Grid>
      <ConfirmStartPlanDialog
        plan={plan}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

const ShowInProgressPlanAction = ({ plan }: { plan: Plan }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Grid item xs={12} mt={2} className="media-screen">
        <Button
          fullWidth
          onClick={() => setIsModalOpen(true)}
          variant="contained"
        >
          Concluir Plano
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          Conclua o plano para sinalizar que você alcançou seus objetivos e está
          pronto para encerrar esta jornada!
        </Typography>
      </Grid>
      <ConfirmFinishPlanDialog
        plan={plan}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export const ShowPlanAction = ({ plan }) => {
  return (
    <Grid container spacing={2}>
      {plan.status === PlanStatuses.DRAFT && (
        <ShowDraftPlanAction plan={plan} />
      )}
      {plan.status === PlanStatuses.IN_PROGRESS && (
        <ShowInProgressPlanAction plan={plan} />
      )}
    </Grid>
  );
};
