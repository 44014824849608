import * as React from 'react';
import classes from 'clsx';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Logo from '@/react/components/appbar/logo.component';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { formatDate } from '@/utils/date-parser';

export const PrintHeader = () => {
  const { isFetching, info } = useBootstrapData();

  if (isFetching) return null;

  return (
    <Container
      className={classes('print-header', 'media-print')}
      maxWidth={false}
      sx={{
        width: '100%',
        zIndex: 9999,
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          height: 64,
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Logo />
        </div>
        <Stack direction="row" gap={1}>
          <Typography variant="caption">
            {info.colaborador.empresa.empresa}
          </Typography>
          <Typography variant="caption">{info.email}</Typography>
          <Typography variant="caption">Data: {formatDate()}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
};
