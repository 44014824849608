import React from "react";
import { Container, Grid, Box, Typography, Link, LinearProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSubsidyPolicies } from '@/react/data/subsidy/useSubsidyPolicies';
import { formatMoney } from "@/react/utils/money";
import { EmptyPolicies } from "./empty-policies.component";
import { formatDate } from "@/react/utils/date";

export const RefundPolicies = () => {

  const {
    isFetching: isFetchingPolicies,
    policy,
    refundValue,
  } = useSubsidyPolicies();


  return (
    <Container component="div">
      <Grid container alignItems="center" justifyContent="flex-start" my={2} gap={1}>
        <Grid item>
          <Link href="/meus-reembolsos" color="neutral.800" sx={({palette}) => ({
              ':hover': {
              color: palette.neutral[800],
              }})}>
            <ArrowBackIosIcon fontSize="small"/>
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h5">Políticas</Typography>
        </Grid>
        <Grid item xs={12} gap={1}>
          <Typography color="neutral.800" variant="body3">
            Confira as políticas disponíveis para solicitar o seu reembolso, no caso de dúvidas procure o seu RH.
          </Typography>
        </Grid>
      </Grid>
        <>
        {isFetchingPolicies && (<LinearProgress />)}
        {!isFetchingPolicies && !policy && (<EmptyPolicies />)}
        {policy && refundValue && (
      <Box
        height={'100%'}
        width={'100%'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        my={2}
        sx={({palette}) => ({ minHeight:156, border: `1px solid ${palette.neutral[300]}`, backgroundColor: `${palette.neutral[50]}`})}
      >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary.900">{policy.name}</Typography>
            </Grid>
            {!!policy.limits.amount_available && (
            <Grid container xs={12}>
              <Grid item xs={2}>
                <Typography variant="body3" fontWeight={600}>Saldo disponível</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" color="success.700">{formatMoney(refundValue)}</Typography>
              </Grid>
            </Grid>
            )}
            {(policy.limits.amount_limit_per_purchase_cents !== null && policy.limits.percentage_limit_per_purchase !== null)
             && (
              <Grid container xs={12}>
                <Grid item xs={2}>
                  <Typography variant="body3" fontWeight={600}>Limite por compra</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body3">
                    <Typography component="span" variant="body3" color="success.700">{parseInt(policy.limits.percentage_limit_per_purchase) + '%'}</Typography> até <Typography component="span" variant="body3" color="success.700">{formatMoney(refundValue)}</Typography> por compra</Typography>
                </Grid>
              </Grid>
            )}
              {!!policy.limits.amount_limit_per_cycle_cents && (
                <Grid container xs={12}>
                    <Grid item xs={2}>
                      <Typography variant="body3" fontWeight={600}>Ciclo do saldo</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body3">
                        <Typography component="span" variant="body3" color="success.700">{formatMoney(policy.limits.amount_limit_per_cycle_cents)}</Typography> por semestre
                        {!!policy.ends_at && (
                          <Typography variant="body3">Com renovação no dia {formatDate(policy.ends_at)}.</Typography>
                        )}
                        <Typography variant="body3">O saldo não é cumulativo. O saldo não usado até o dia da renovação é perdido.</Typography>
                      </Typography>
                    </Grid>
                </Grid>
              )}
            <Grid container xs={12}>
            <Grid item xs={2}>
            <Typography variant="body3" fontWeight={600}>Recursos Elegíveis</Typography>
              </Grid>
              {policy.configuration.eligible_resource_type === "360" ? (
              <Grid item xs={10}>
                <Typography variant="body3">Itens na plataforma Galena: Todos </Typography>
                <Typography variant="body3">Itens fora da plataforma Galena: Cursos e Eventos e palestras.</Typography>
              </Grid>
              ) :
              <Grid item xs={10}>
               <Typography variant="body3">Itens na plataforma Galena: Todos </Typography>
            </Grid>
              }
            </Grid>
            <Grid container xs={12}>
            <Grid item xs={2}>
            <Typography variant="body3" fontWeight={600}>Descrição</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body3" >{policy.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        )}
        </>
    </Container>
  );
};

export default RefundPolicies;
