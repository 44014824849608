import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid,
  FormHelperText,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface FileUploadProps {
  onFilesChange: (files: any) => void;
  required?: boolean;
  error: boolean;
  helperText: any;
  id: string;
  defaultFiles?: File[];
}


const FileItemUploaded = ({ fileName, fileSize, handleRemoveFile, index, isFileTooLarge }) => {
  const handleFileSize = (size) => {
    const sizeInKb = size / 1024;
    const sizeInMb = size / 1024 ** 2;

    if (sizeInMb < 1) {
      return `${Math.floor(sizeInKb)} Kb`;
    } else {
      return `${Math.floor(sizeInMb)} Mb`;
    }
  }

  return (
    <Grid item key={index} xs={12} style={{ paddingLeft: 0, paddingTop: 0 }}>
      <Box sx={({ palette }) => ({
          backgroundColor: palette.neutral[200],
          width: '50%',
          height: '72px',
          minWidth: '280px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 0,
          marginBottom: '16px',
        })}
      >
        <IconButton
          disabled
          style={{
            marginLeft: '16px',
            marginTop: '24px',
            marginBottom: '24px',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <AttachFileIcon />
        </IconButton>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography
            variant="body3"
            sx={{ flexGrow: 1, paddingTop: 0, marginLeft: '16px' }}
          >
            {fileName}
          </Typography>
          <Typography
            variant="body3"
            sx={{ flexGrow: 1, paddingTop: 0, marginLeft: '16px' }}
          >
            {handleFileSize(fileSize)}
          </Typography>
        </Box>

        <IconButton
          color="primary"
          onClick={() => handleRemoveFile(index)}
          sx={{
            marginLeft: 'auto',
            paddingTop: 0,
            paddingBottom: 0,
            marginRight: '16px',
          }}
        >
          <DeleteOutlineIcon sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
      {isFileTooLarge && (
        <Typography variant="caption" color="error">
          (Arquivo excede 2MB e não pode ser anexado)
        </Typography>
      )}
    </Grid>
  );
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  required = false,
  error,
  helperText,
  id,
  defaultFiles = [],
}) => {
  const [files, setFiles] = useState<File[]>(defaultFiles);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setFiles(defaultFiles);
  }, [defaultFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
    event.target.value = null;
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      {files.length > 0 && (
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          {files.map((file, index) => {
            const fileSizeInMB = file.size / 1024 ** 2; // Convertendo para MB
            const isFileTooLarge = fileSizeInMB > 2; // Verificando se é maior que 2MB
            const fileName = file instanceof File ? file.name : file; // Se o arquivo vier do backend (string)

            return (
              <FileItemUploaded
                key={index}
                fileSize={file.size}
                fileName={fileName}
                handleRemoveFile={handleRemoveFile}
                index={index}
                isFileTooLarge={isFileTooLarge}
              />
            );
          })}
        </Grid>
      )}
      <input
        accept="image/*,.pdf"
        style={{ display: 'none' }}
        id={id}
        multiple
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        component="span"
        color={error ? 'error' : 'primary'}
        onClick={handleButtonClick}
        style={{ marginTop: files.length === 0 ? 16 : 0 }}
      >
        Anexar documento
      </Button>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default FileUpload;
